import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets= ['render'];
    static values = { url: String, httpMethod: String };

    connect() {
    }

    replaceContent() {
        let controller = this;
        let request = this.request();

        request.done(function(data) {
            // "this" is added to controller variable because "this" gets replaced by the request object
            controller.requestDone(data,
            controller.renderTarget);
        });
        request.fail(controller.requestError);

        this.request();
    }

    request() {
        return $.ajax({
            url: this.urlValue,
            type: this.httpMethodValue,
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });
    }

    requestDone(data, target) {
        target.innerHTML = data;
    }

    requestError(data) {
        console.error(data);
    }
}