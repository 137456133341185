import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        graphId: String,
        bars: Array,
        yAxisTitle: String,
        forwardLink: String,
        klass: String
    }

    connect() {
        this.loadGraph()
    }

    loadGraph() {
        let dispatch = this.dispatchFilter
        let linkValue = this.forwardLinkValue
        let klass = this.klassValue
        Highcharts.chart(this.graphIdValue, {
            chart: {
                type: 'column',
            },
            credits: {
                enabled: false,
            },
            title: {
                text: ''
            },
            xAxis: {
                labels: {
                    enabled: false,
                    align: "right",
                    style: {
                        textOverflow: 'none',
                        whiteSpace: 'nowrap'
                    },
                },
                categories: this.barsValue.map(bar => bar.name),
                crosshair: true,
            },
            yAxis: {
                min: 0,
                title: { text: this.yAxisTitleValue },
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px"></span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b> {point.y}</b></td></tr>',
                footerFormat: '</table>',
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    pointWidth: 80,
                },
                bar: {
                    dataLabels: {
                        padding: 0,
                    }
                },
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: (data) => {
                                dispatch(linkValue, data, klass)
                            }
                        }
                    }
                }
            },
            series: this.barsValue.map(bar => {
                bar.data = [{
                    name: bar.name,
                    y: bar.data
                }];
                return bar
            })
        })
    }

    dispatchFilter(linkValue, data, klass) {
        $(`.${klass.replace('OEM::','')}_datatable_search`).val('')
        $(`.${klass.replace('OEM::','')}_datatable_search`)[0].classList.remove('active')
        $(`a[data-id=${linkValue}]`)[0].click();
        window.scrollTo(0, 0);
        switch (klass) {
            case 'OEM::Vulnerability':
                $('#oem_vulnerability_table_datatable_filters button.text-primary.mt-1')[0].click()
                var value = $(`#Severity_select_filter option:contains("${data.point.name}")`).val();
                mdb.Select.getInstance($('#oem_vulnerability_table_datatable_filters #Severity_select_filter')[0]).setValue(value);
                mdb.Select.getInstance($('#Status_select_filter')[0]).setValue('Open');
                $('#oem_vulnerability_table_datatable_filters button.btn.btn-primary')[0].click()
                break
            case 'OEM::PatchDetail':
                $('#oem_patch_detail_table_datatable_filters button.text-primary.mt-1')[0].click()
                mdb.Select.getInstance($('#oem_patch_detail_table_datatable_filters #Severity_select_filter')[0]).setValue(data.point.name);
                $('#oem_patch_detail_table_datatable_filters button.btn.btn-primary')[0].click()
                break
        }
    }
}