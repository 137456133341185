import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form", "partner", "chip", "shifts"]

    addFilter(event) {
        // add partner filter badge to datatable
        let selection = document.getElementById("filter_pills")

        selection.innerHTML = ''

        const chip_structure = (text, paramName) => {
            return `<div class="chip" data-mdb-close="true" data-abakis-filter-target="chip" data-param-name="${paramName}">
            ${text}
            <i class="close fas fa-times" data-action="click->abakis-filter#clearFilter"></i></div>`
        }

        const selected_partner = this.partnerTarget.selectedOptions[0]
        const selected_shift = this.shiftsTarget.selectedOptions[0]
        
        // default values shouldn't appear as badge

        if (selected_partner && (selected_partner.label != "Select")) {
            selection.innerHTML += chip_structure(selected_partner.label, "partner_id")
        }

        if (selected_shift && (selected_shift.label != "Without Office Hours")) {
            selection.innerHTML += chip_structure(selected_shift.label, "mdr_review_shift")
        }

        let currentTable = $($.fn.dataTable.tables({ visible: true })[0]).DataTable();

        $.ajax({
            method: 'GET',
            type: 'GET',
            url: location.href,
            dataType: "json",
            context: this,
            data: { partner_id: selected_partner ? selected_partner.value : '', mdr_review_shift: selected_shift ? selected_shift.value : null },
            success: function(data){
                $("#abakis-filter select").each(function() {
                    $(this).val($(this).find('option:first').val(""));
                });
                $('button#filter-button-submit').addClass('disabled').attr('disabled', 'disabled')
                $("#abakisFilterModal").modal('hide');

                const url = new URL(currentTable.ajax.url())

                let params = url.searchParams

                params.set("partner_id", data.partner_id)
                params.set("mdr_review_shift", data.mdr_review_shift)

                
                currentTable.ajax.url(url.toString()).load();
            },
            error: function (xhr, status, error) {
                console.log('Error filtering Abakis console 1 by partner: ' + xhr.responseText + ", Error: " + error)
            }
        })

    }

    clearFilter(event) {
        // remove chips and redraw the table
        let element = event.currentTarget.parentElement
        const filterToDeselect = element.dataset.paramName

        if (this.hasChipTarget){
            element.remove();
            $.ajax({
                method: 'GET',
                type: 'GET',
                url: location.href,
                dataType: "json",
                success: function (data) {
                    // set the source url back to the default url
                    let currentTable = $($.fn.dataTable.tables({ visible: true })[0]).DataTable(); //whichever datatable is currently showing
                    let url = new URL(currentTable.ajax.url())
                    url.searchParams.delete(filterToDeselect)
                    currentTable.ajax.url(url).load();
                },
                error: function (xhr, status, error) {
                    console.log('Error filtering Abakis console 2 by partner: ' + xhr.responseText + ", Error: " + error)
                }
            })
        }
    }


}