import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let jsData = document.getElementById("js-data");
    let tenantId = jsData.dataset.tenantid;
    let webButton = document.getElementById("salesforce-credentials-button-web");
    webButton.onclick = onWebButtonClick;
    let ccButton = document.getElementById("salesforce-credentials-button-cc");
    ccButton.onclick = onCCButtonClick;
    let credentialsForm = document.getElementById("salesforce-credentials-form");
    let credentialsConnectButton = document.getElementById("credentials-connect");
    let credentialsResetButton = document.getElementById("credentials-reset");
    let accountNameInput = document.getElementById("account_name");
    let salesforceUrlInput = document.getElementById("salesforce_url");
    let salesforceConsumerKeyInput = document.getElementById("salesforce_consumer_key");
    let salesforceSecretKeyInput = document.getElementById("salesforce_consumer_secret");
    let inputs = [accountNameInput, salesforceUrlInput, salesforceConsumerKeyInput, salesforceSecretKeyInput]
    inputs.forEach(element => element.addEventListener('input', onInputChange));

    function oauthCreateAuthUrl() {
      return new Promise(function (resolve, reject) {
        let oauthBaseUrl = salesforceUrlInput.value;
        let oauthUrl = new URL('/services/oauth2/authorize', oauthBaseUrl.toString());
        let oauthState = { tenant_id: tenantId, account_name: accountNameInput.value };
        oauthUrl.searchParams.set('client_id', salesforceConsumerKeyInput.value);
        let currentUrl = new URL(document.URL);
        currentUrl.path = "/salesforce/oauth";
        oauthUrl.searchParams.set('redirect_uri', jsData.dataset.oauthredirecturl);
        oauthUrl.searchParams.set('response_type', 'code');
        oauthUrl.searchParams.set('state', JSON.stringify(oauthState));
        resolve(oauthUrl);
      });
    }

    function onWebButtonClick() {
      webButton.classList.remove('btn-primary');
      webButton.classList.add('btn-secondary');
      webButton.disabled = true;
      ccButton.classList.remove('btn-secondary');
      ccButton.classList.add('btn-primary');
      ccButton.disabled = false;
      credentialsConnectButton.onclick = oauthWebConnect;
      inputs.every(i => i.parentNode.style.display = "block")
      credentialsForm.style.display = "block";
      credentialsForm.action = "salesforce_oauth"
      formReset()
    }
    function onCCButtonClick() {
      ccButton.classList.remove('btn-primary');
      ccButton.classList.add('btn-secondary');
      ccButton.disabled = true;
      webButton.classList.remove('btn-secondary');
      webButton.classList.add('btn-primary');
      webButton.disabled = false;
      credentialsConnectButton.onclick = oauthCCConnect;
      inputs.every(i => i.parentNode.style.display = "block")
      credentialsForm.style.display = "block";
      credentialsForm.action = "salesforce_oauth_cc"
      formReset()
    }
    function onInputChange() {
      if (inputs.every(i => i.value !== "")) {
        // all inputs are filled
        credentialsConnectButton.classList.add('btn-primary');
        credentialsConnectButton.classList.remove('btn-secondary');
        credentialsConnectButton.disabled = false;
      } else {
        credentialsConnectButton.classList.remove('btn-primary');
        credentialsConnectButton.classList.add('btn-secondary');
        credentialsConnectButton.disabled = true;
      }
      if (inputs.every(i => i.value === "")) {
        // all inputs are empty
        credentialsResetButton.classList.remove('btn-danger');
        credentialsResetButton.classList.add('btn-secondary');
        credentialsResetButton.disabled = true;
      } else {
        credentialsResetButton.classList.add('btn-danger');
        credentialsResetButton.classList.remove('btn-secondary');
        credentialsResetButton.disabled = false;
      }
    }

    function formReset() {
      credentialsResetButton.classList.remove('btn-danger');
      credentialsResetButton.classList.add('btn-secondary');
      credentialsResetButton.disabled = true;
      credentialsConnectButton.classList.remove('btn-primary');
      credentialsConnectButton.classList.add('btn-secondary');
      credentialsConnectButton.disabled = true;
      accountNameInput.value = "";
      salesforceUrlInput.value = "";
      salesforceConsumerKeyInput.value = "";
    }

    function saveOauthTemp() {
      let oauthTempUrl = new URL('/salesforce_oauth_temp', document.URL);
      let formData = new FormData();
      formData.append("account_name", accountNameInput.value);
      formData.append("salesforce_url", salesforceUrlInput.value);
      formData.append("salesforce_consumer_key", salesforceConsumerKeyInput.value);
      formData.append("salesforce_consumer_secret", salesforceSecretKeyInput.value);
      return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open("POST", oauthTempUrl);
        xhr.send(formData);
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.response);
          } else {
            reject(new Error(`${xhr.status} - ${xhr.statusText}`))
          }
        };
        xhr.onerror = function () {
          reject(new Error(`${xhr.status} - ${xhr.statusText}`))
        };
      });
    }

    function saveOauthCC() {
      credentialsForm.submit()
    }

    function oauthWebConnect() {
      oauthCreateAuthUrl()
        .then(function (oauthAuthUrl) {
          return saveOauthTemp()
            .then(function (_) {
              window.location.href = oauthAuthUrl;
            })
            .catch(function (err) {
              alert(`failed to save forum: ${err.message}`);
            })
        })
        .catch(function (err) {
          alert(`Invalid configuration: ${err.message}`);
        })
    }

    function oauthCCConnect() {
      saveOauthCC()
    }

    credentialsResetButton.onclick = formReset;

    formReset();
  }
}
