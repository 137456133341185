import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['render']
  static values = { data: Object, dataName: String, title: String, yAxisTitle: String, categories: Array,
      series: Array, stacking: Boolean, colors: Array, customHover: Boolean }

  connect() {
    new Highcharts.chart(this.renderTarget, this.defaultOptions())
  }

  defaultOptions() {
      const categories = this.categoriesValue;
      const graph_series = this.seriesValue;

    return {
        chart: {
            type: 'column'
        },
        title: {
            text: this.titleValue,
            align: 'left'
        },
        colors: this.colorsValue,
        xAxis: {
            categories: categories
        },
        yAxis: {
            min: 0,
            title: {
                text: this.yAxisTitleValue
            },
            stackLabels: {
                enabled: true
            }
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: `${this.customHoverValue ? '{point.customMessage}' : '{series.name}: {point.y}'}<br/>Total: {point.stackTotal}`
        },
        plotOptions: {
            column: {
                ...{stacking: this.stackingValue ? 'normal' : null},
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: graph_series,
        lang: {
            noData: 'No detections'
        },
        exporting: {
            buttons: {
                contextButton: {
                    enabled: false
                },
                printButton: {
                    enabled: false,
                }
            }
        },
        credits: {
            enabled: false
        }
    }
    }
}