export default function closeHamburgerWindowChange() {
  //close hamburger toolbar if its open and window size is greater than 770 pixels
  $(window).resize(function() {
    if($(window).width() > 770){
      var hamburgerDown = $('div#hamburger-menu.dropdown-menu.collapse.in');
      if(hamburgerDown.attr('aria-expanded')=== "true"){
        hamburgerDown.collapse('hide');
      }
    }
  })
}
