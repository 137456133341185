import { Controller } from "@hotwired/stimulus"

import { contentReveal } from "./templates/content_reveal"

class FetchFiltersController extends Controller {
  static targets = ["filters"]

  fetchFilters({ params: { id, table } }) {
    this.filtersTarget.innerHTML = contentReveal(id, table) 
    this.element.querySelector('.btn-primary').classList.add('pe-none')
    this.element.querySelector('.btn.btn-primary').classList.remove('btn', 'btn-primary')   
  }  
}

export default FetchFiltersController