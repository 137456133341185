import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"];

  connect() {
    if (this.selectTarget) {
      new mdb.Select(this.selectTarget, {
        filter: this.selectTarget.dataset.selectFilter === 'true',
        clearButton: this.selectTarget.dataset.selectClearButton === 'true',
      });
      this.selectedVal = null;
      this.selectTarget.addEventListener('valueChange.mdb.select', this.selectChanged.bind(this));
    }
  }

  selectChanged(event) {
    this.selectedVal = event.target.value;

    // If display the selected values or share as params

    if ($("#select-id").length) {
      $("#select-id").val(this.selectedVal);
      $("#select-name").val(event.target.options[event.target.selectedIndex].text);
    }
  }
}
