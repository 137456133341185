import TableExemptions from "./table_exemptions_controller";

export default class extends TableExemptions {
    executeAction(event){
        if(this.hasItems()){
            this.disableExecuteButton();
            this.hideModal();
            this.showProgressBanner();
            let data = {}
            data[this.selectedItemsKeyValue] = Object.keys(this.selectedItems);
            data.note = $(event.currentTarget).closest('div[class="modal-content"]').find("textarea#note").val();

            let thisController = this;
            $.ajax({
                url:  this.executionUrlValue,
                method: 'POST',
                data: data,
                success: (resp) => {
                    thisController.hideProgressBanner();
                    thisController.enableExecuteButton();
                    thisController.flashSuccessBanner();
                    thisController.clear();
                    thisController.datatableItem.draw();
                    thisController.alternateTablesToReload.forEach(table => {
                        table.draw();
                    })
                },
                error: (resp) => {
                    thisController.hideProgressBanner();
                    thisController.flashErrorBanner();
                }
            });
        }
    }
    
    executeSingle(event){
        let itemId = event.currentTarget.dataset.id;
        let data = {}
        data[this.selectedItemsKeyValue] = [itemId];
        data.note = $(event.currentTarget).closest('tr').find("textarea[name='stale_notes']").val();
        let thisController = this;
        this.showProgressBanner();
        $.ajax({
          url:  this.executionUrlValue,
          method: 'POST',
          data: data,
          success: (resp) => {
              thisController.hideProgressBanner();
              thisController.flashSuccessBanner();
              thisController.clear();
              thisController.datatableItem.draw();
              thisController.alternateTablesToReload.forEach(table => {
                table.draw();
              })
          },
          error: (resp) => {
              thisController.hideProgressBanner();
              thisController.flashErrorBanner();
            }
        });
    }
};