import { Controller } from '@hotwired/stimulus'

class AppNavigationController extends Controller {

  static targets = [
    'icon',
    'mobile-toggle',
    'tablet-toggle',
    'tenantSearchInput',
    'tenant'
  ]

  tenantSearch() {
    const filter = this.tenantSearchInputTarget.value.toLowerCase()
    this.#showOptions()

    const valueExist = !!filter.length
    if (valueExist) {
      this.tenantTargets.forEach(el => {
        const elText = el.textContent.trim().toLowerCase()
        const isIncluded = elText.includes(filter)
        if (!isIncluded) {
          el.style.display = 'none'
        }
      })
    }
  }

  darkmodeToggle() {
    fetch('/dark_mode', {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      }
    })
    document.body.classList.toggle('dark')
    if (document.body.classList.contains('dark')) {
      this.iconTarget.classList.remove('fa-moon')
      this.iconTarget.classList.add('fa-sun-bright')
    } else {
      this.iconTarget.classList.remove('fa-sun-bright')
      this.iconTarget.classList.add('fa-moon')
    }
  }

  toggleSidebarSlim() {
    fetch('/sidebar_mode', {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      }
    })
    this.#sidebarOnDesktop()
  }

  toggleSidebarMobile() {
    this.#sidebarOnMobile()
    this.#overlay()
    this.#hideLogo()
  }

  #showOptions() {
    this.tenantTargets.forEach(el => el.style.display = 'flex')
  }

  #sidebarOnDesktop() {
    document.body.classList.toggle('desktop-nav--slim')
  }

  #sidebarOnMobile() {
    document.body.classList.toggle('mobile-nav--active')
    document.querySelector('.app__main').classList.toggle('pe-none')
  }

  #overlay() {
    if (document.body.classList.contains('mobile-nav--active')) {
      const overlay = document.createElement('div')
      overlay.classList.add('mobile-overlay')
      overlay.dataset.controller = 'close-mobile-sidebar'
      overlay.dataset.action = "click->close-mobile-sidebar#close"
      document.body.appendChild(overlay)
    }
  }

  #hideLogo() {
    document.querySelector('.topbar__logo').classList.add('d-none')
  }
}

export default AppNavigationController