import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['csvLink', 'dateRange', 'render', 'search']
  static values = {
    columns: Array,
    defaultSort: Array,
    rowExpansion: Boolean,
    url: String
  }

  connect() {
    this.render()
  }

  render() {
    const columns = this.columns()
    const dateRange = this.dateRangeTarget
    const order = this.defaultSortValue
    const searchTarget = this.searchTarget
    const urlValue = this.urlValue

    return $(this.renderTarget).DataTable({
      deferRender: true,
      language: {
        loadingRecords: "Loading...&nbsp;",
        processing: "Loading events...",
        search: "",
        zeroRecords: "No events found."
      },
      dom: '<"top">tr<"bottom"<"d-flex justify-content-between align-items-center border-top pt-3"<""i><"d-flex align-items-center"lp>>><"clear">',
      order: order,
      orderCellsTop: true,
      pageLength: 25,
      processing: true,
      responsive: true,
      serverSide: true,
      ajax: {
        url: urlValue,
        data: function (d) {
          d.search.value = $(searchTarget).val()
          d.dtrange = $(dateRange).val()
        },
        dataFilter: function (data) {
          const json = jQuery.parseJSON(data)
          json.recordsTotal = json.syslog_events.iTotalRecords
          json.recordsFiltered = json.syslog_events.iTotalDisplayRecords
          json.data = json.syslog_events.aaData
          return JSON.stringify(json)
        }
      },
      columns: columns
    })
  }

  search() {
    // if (this.noSearchValue()) { return }
    this.drawTable()
  }

  reset() {
    if (this.noSearchValue()) { this.drawTable() }
  }

  noSearchValue() {
    return this.searchTarget.value === ""
  }

  clearFilter() {
    this.dateRangeTarget.value = this.defaultDateTime()
    this.drawTable()
  }

  drawTable() {
    $(this.renderTarget).DataTable().draw()
    this.setCsvPath()
  }

  setCsvPath() {
    if (!this.hasCsvLinkTarget) { return }

    let hrefValue = this.csvLinkTarget.href
    let dateRange = this.dateRangeTarget.value
    if (dateRange === "") { dateRange = this.defaultDateTime() }

    hrefValue = hrefValue.slice(0, hrefValue.indexOf('?')).concat('', `?search=${encodeURIComponent(this.searchTarget.value)}`).concat('', `&dtrange=${dateRange}`)
    this.csvLinkTarget.href = hrefValue
  }

  defaultDateTime() {
    return `${new Date(new Date().setHours(-49)).toLocaleDateString('us-en')} 00:00:00 - ${new Date().toLocaleDateString('en-us', { timeZone: 'UTC' })} 23:59:59`
  }

  toggleRow(e) {
    if (!["TD", "I"].includes(e.target.tagName)) { return }
    if (["child", ""].includes(e.target.closest('tr'))) { return }

    const tr = e.target.closest("tr")
    const tdi = tr.getElementsByClassName("fa")[0]
    const row = $(this.renderTarget).DataTable().row(tr)

    if (row.child.isShown()) {
      // This row is already open - close it
      row.child.hide()
      tr.classList.remove('shown')
      tdi.classList.remove('fa-minus-square')
      tdi.classList.add('fa-plus-square')
    }
    else {
      // Open this row
      row.child(this.expandedRow(row.data())).show()
      tr.classList.add('shown')
      tdi.classList.remove('fa-plus-square')
      tdi.classList.add('fa-minus-square')
    }
  }

  columns() {
    let columnArray = []
    if (this.rowExpansionValue) {
      columnArray.push(
        {
          className: 'details-control',
          orderable: false,
          data: null,
          defaultContent: '',
          render: function () { return '<i class="fa fa-plus-square" aria-hidden="true"></i>' },
          width: "15px"
        }
      )
    }
    for (const index in this.columnsValue) {
      const column = this.columnsValue[index]
      let columnConfig = {
        data: column.name,
        orderable: column.orderable
      }
      if (column.max_chars != null) {
        columnConfig.render = function (data) {
          if (data != null) {
            const truncatedData = `${data.substring(0, column.max_chars)}…`
            return data.length > column.max_chars && truncatedData != "" ? truncatedData : data
          } else {
            return ''
          }
        }
      }
      columnArray.push(columnConfig)
    }
    return columnArray
  }

  expandedRow(d) {
    let expandedTable = '<table class="table table-striped text-dark" style="padding-left:50px;">'

    if (![null, "", " "].includes(d.full_log)) {
      for (const key in d.full_log) {
        if (d.full_log.hasOwnProperty(key)) {
          if (![null, "", " ", "-", "message_raw", "source_address", false].includes(d.full_log[key])) {
            if (key !== 'description') {
              expandedTable += this.fullLogRow(key, d.full_log[key])
            }
          }
          if (key === 'description') {
            expandedTable += `<tr><td><strong>${key}</strong></td><td>${d.full_log['description']}</td></tr>`
          }
        }
      }
    } else {
      if (d.cef_parsed) {
        for (const column in this.cefParsedColumns(d)[0]) {
          if (column[1] != "") { expandedTable += this.defaultExpandedRow(column[0], column[1]) }
        }

        if (!["", null, "null", " "].includes(d.application_protocol)) {
          expandedTable += this.defaultExpandedRow("Application Protocol:", d.application_protocol)
        }

        for (const column in this.cefParsedColumns(d)[1]) {
          if (column[1] != "") { expandedTable += this.defaultExpandedRow(column[0], column[1]) }
        }

        if (d.cef_full_hash != "") { expandedTable += d.cef_full_hash }

        if (d.domain != "") { expandedTable += this.defaultExpandedRow("Domain:", d.domain) }
      } else if (d.carbon_black_parsed) {
        for (const column in this.carbonBlackParsedColumns(d)) {
          if (column[1] != "") { expandedTable += this.defaultExpandedRow(column[0], column[1]) }
        }
      } else {
        for (const column in this.miscExpandedRowColumns(d)[0]) {
          if (!["", null].includes(column[1])) { expandedTable += this.defaultExpandedRow(column[0], column[1]) }
        }

        for (const column in this.miscExpandedRowColumns(d)[1]) {
          if (column[1] != "") { expandedTable += this.defaultExpandedRow(column[0], column[1]) }
        }
      }
      expandedTable += this.defaultExpandedRow("Raw Event:", d.message_raw)
    }
    expandedTable += '</table>'

    return expandedTable
  }

  defaultExpandedRow(displayName, value) {
    return `<tr><td><strong>${displayName}</strong></td><td>${value}</td></tr>`
  }

  fullLogRow(key, logItem) {
    return `<tr><td><strong>${key}:</strong></td><td>${this.formattedRowValue(logItem)}</td></tr>`
  }

  formattedRowValue(logItem) {
    if (typeof (logItem) == 'object') {
      return JSON.stringify(logItem)
    } else {
      return String(logItem).replaceAll("<", "&lt").replaceAll(">", "&gt")
    }
  }

  defaultExpandedRowColumns(d) {
    return [
      ["Username:", d.username],
      ["Action:", d.action],
      ["Source Address:", d.source_address],
      ["Source City:", d.source_city],
      ["Source Country:", d.source_country_code],
      ["Source Region Name:", d.source_region_name],
      ["Source Time Zone:", d.source_time_zone]
    ]
  }

  cefParsedColumns(d) {
    return [
      [
        ["Device Vendor:", d.cef_device_vendor],
        ["Device Product:", d.cef_device_product],
        ["Device Version:", d.cef_device_version],
        ["Signature ID:", d.cef_signature_id],
        ["Event Name/ID:", d.cef_event_name],
        ["Severity:", d.cef_severity],
        ["Event Category:", d.event_category]
      ],
      [
        ["Activity:", d.activity],
        ["Device:", d.device],
        ["System Name:", d.device_hostname],
        ["File Name:", d.file_name],
        ["Destination Hostname:", d.destination_hostname],
        ["Destination Username:", d.destination_username],
        ["Destination MAC Address:", d.destination_macaddr],
        ["Destination Domain:", d.destination_domain],
        ["Destination IP:", d.destination_ip_address],
        ["Destination Port:", d.destination_port],
        ["Source Hostname:", d.source_hostname],
        ["Source Username:", d.source_username],
        ["Source MAC Address:", d.source_mac],
        ["Source Domain:", d.source_domain],
        ["Source IP:", d.source_ip_address],
        ["Source Port:", d.source_port],
        ["Request URL:", d.request_url]
      ]
    ]
  }

  carbonBlackParsedColumns(d) {
    return [
      ["Username:", d.username],
      ["IP Address:", d.ip_address],
      ["File Path:", d.file_path],
      ["File Name:", d.file_name],
      ["File Hash:", d.file_hash],
      ["File Trust:", d.file_trust],
      ["File Threat:", d.file_threat],
      ["CB Text:", d.cb_text],
      ["CB Type:", d.cb_type],
      ["CB SubType:", d.subtype],
      ["Process:", d.process],
      ["Process Trust:", d.process_trust],
      ["Process Threat:", d.process_threat],
      ["Installer Name:", d.installer_name],
      ["Policy:", d.policy]
    ]
  }

  miscExpandedRowColumns(d) {
    return [
      [
        ["Facility:", d.facility],
        ["Severity:", d.severity],
        ["Priority:", d.pri],
        ["Action:", d.action],
        ["Hostname:", d.hostname],
        ["Source:", d.source],
        ["Source Address:", d.source_address],
        ["Source City:", d.source_city],
        ["Source Country:", d.source_country_code],
        ["Source Region Name:", d.source_region_name],
        ["Source Time Zone:", d.source_time_zone]
      ],
      [
        ["Source UID:", d.source_uid],
        ["Source Username:", d.source_username],
        ["Destination:", d.destination],
        ["Destination City:", d.destination_city],
        ["Destination Country:", d.destination_country_code],
        ["Destination Region Name:", d.destination_region_name],
        ["Destination Time Zone:", d.destination_time_zone],
        ["Domain:", d.domain],
        ["Username:", d.username]
      ]
    ]
  }
}
