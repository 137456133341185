import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['csvLink', 'render', 'search', 'rotateIcon', 'selectInput', 'datepicker', 'filterPills', 'monthYearInput']
    static values = {
        klass: String,
        scope: String,
        scopeVal: String,
        columns: Array,
        defaultSort: Array,
        rowExpansion: Boolean,
        tableId: String
    }

    connect() {
        this.render()
        this.initFilterInputs()
    }

    render() {
        const controller= this
        const order = controller.defaultSortValue
        const searchTarget = controller.searchTarget
        const columns = controller.columnsValue

        $(this.renderTarget)
            .on('preXhr.dt', function ( e, settings, data ) {
                controller.startSpinner();
            }).on( 'draw.dt', function () {
                controller.stopSpinner();
            }).DataTable({
            order: order,
            pageLength: 100,
            lengthMenu: [100, 500, 1000, 2000],
            ajax: {
                url: '/qualys_datatable_data',
                data: (d) => {
                    d.search.value = $(searchTarget).val();
                    const additionalData = {
                        klass: this.klassValue
                    };
                    if (this.scopeValue !== '') {
                        additionalData.scope = this.scopeValue;
                    }
                    if (this.scopeValValue !== '') {
                        additionalData.scope_val = this.scopeValValue;
                    }
                    return $.extend({}, d, additionalData);
                }
            },
            serverSide: true,
            dom: '<"top">tr<"bottom"<"d-flex justify-content-between align-items-center border-top pt-3"<""i><"d-flex align-items-center"lp>>><"clear">', // This sets the order of the datatable elements, and puts the "entries per page" at the bottom
            columns: columns
        })
    }

    redraw(filters = {}) {
        const params = Object.entries(filters)
            .map(([key, value]) => `filters[${encodeURIComponent(key)}]=${encodeURIComponent(value)}`)
            .join('&');
        $(this.renderTarget).DataTable().ajax.url(`/qualys_datatable_data?${params}`).load();
    }

    getFilters() {
        let filters = {}
        this.selectInputTargets.forEach(target => {
            filters[$(target).data('filter-column')] = $(target).val();
            $(this.filterPillsTarget).find(`div[data-filter-label="${$(target).data('filter-label')}"]`).remove()
            if ($(target).val() != '' && this.validPill($(target))) {
                $(this.filterPillsTarget).append(this.filterPill($(target).data('filter-label'), $(target).val(), $(target).find('option:selected').data('label')))
            }
        });
        this.datepickerTargets.forEach(target => {
            filters[$(target).data('filter-column')] = $(target).find('input').val();
            $(this.filterPillsTarget).find(`div[data-filter-label="${$(target).data('filter-label')}"]`).remove()
            if ($(target).find('input').val() != '' && this.validPill($(target), 'datetime')) {
                $(this.filterPillsTarget).append(this.filterPill($(target).data('filter-label'), $(target).find('input').val(), $(target).find('input').val()))
            }
        });
        return filters;
    }

    downloadCsv() {
        const url = '/qualys_datatable_csv';
        const params = new URLSearchParams();
        let filters = this.getFilters()
        Object.entries($(this.renderTarget).DataTable().ajax.params()).forEach(([key, value]) => {
            params.append(key, JSON.stringify(value));
        });
        params.append('klass', this.klassValue);
        if (this.scopeValue !== '') { params.append('scope', this.scopeValue) }
        if (this.scopeValValue !== '') { params.append('scope_val',  this.scopeValValue) }


        const downloadUrl = `${url}?${params.toString()}&filters=${JSON.stringify(filters)}`;
        window.location.href = downloadUrl;
    }

    uploadFileModal() {
        $("#file-manager-modal").modal().show();
        $('#loading-files')[0].classList.remove('hide');
        fetch(`/historical_report_files`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                $('#loading-files')[0].classList.add('hide');

                const tableBody = document.getElementById('files-table-body');
                tableBody.innerHTML = '';

                if (data.files.length > 0) {
                    data.files.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    data.files.forEach(file => {
                        const row = document.createElement('tr');
                        const nameCell = document.createElement('td');
                        const uploadedOnCell = document.createElement('td');
                        const trashCell = document.createElement('td');
                        const deleteButton = document.createElement('button');

                        deleteButton.innerHTML = "<i class='fa-regular fa-trash-can'></i>";
                        deleteButton.addEventListener('click', () => { this.deleteFileById(file.id) });

                        nameCell.textContent = file.name;
                        uploadedOnCell.textContent = Date(file.created_at).toLocaleString();
                        trashCell.appendChild(deleteButton);

                        row.appendChild(nameCell);
                        row.appendChild(uploadedOnCell);
                        row.appendChild(trashCell);

                        tableBody.appendChild(row);
                    });
                } else {
                    const emptyRow = document.createElement('tr');
                    const emptyCell = document.createElement('td');
                    emptyCell.setAttribute('colspan', '5');
                    emptyCell.textContent = 'No files available.';
                    emptyRow.appendChild(emptyCell);
                    tableBody.appendChild(emptyRow);
                }
                // sort title
                const titleHeader = document.querySelector('#file-header');
                const arrowIcon = document.querySelector('#file-sort-arrow');
                let isAscending = true; // Track sort order
                titleHeader.addEventListener('click', () => {
                    const tableBody = document.getElementById('files-table-body');
                    const rows = Array.from(tableBody.getElementsByTagName('tr'));

                    if (isAscending) {
                        rows.sort((a, b) => a.firstChild.textContent.localeCompare(b.firstChild.textContent));
                        arrowIcon.classList.remove('fa-arrow-down');
                        arrowIcon.classList.add('fa-arrow-up');
                        isAscending = false;
                    } else {
                        rows.sort((a, b) => b.firstChild.textContent.localeCompare(a.firstChild.textContent));
                        arrowIcon.classList.remove('fa-arrow-up');
                        arrowIcon.classList.add('fa-arrow-down');
                        isAscending = true;
                    }

                    rows.forEach(row => tableBody.appendChild(row));
                });
                const uploadedOnHeader = document.querySelector('#file-uploaded-on-header');
                const uploadedfilearrowIcon = document.querySelector('#file-arrow-icon-uploaded-on');
                let fileUploadedisAscending = true; // Track sort order
            })

            .catch(error => {
                $('#loading-files')[0].classList.add('hide');
                $('#other-file-error')[0].classList.remove('hide');
                console.error(error);
            })
    }

    deleteFileById(fileId) {
        $('#other-file-error')[0].classList.add('hide');
        $('#loading-files')[0].classList.remove('hide');
        fetch(`/historical_report_files/${fileId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    this.refreshTable();
                    const submitButton = $("input[type=submit]", this);
                    submitButton.prop("disabled", false);
                    $("#file-manager-modal").modal().hide();
                    $('.modal-backdrop.show').hide()
                } else {
                    $('#other-file-error')[0].classList.remove('hide');
                    console.error('Failed to delete file.');
                }
            })
            .catch(error => {
                $('#other-file-error')[0].classList.remove('hide');
                console.error(error);
            });
    }

    refreshTable() {
        this.drawTable()
        this.startSpinner()
    }

    startSpinner() {
        this.rotateIconTarget.classList.add("rotate");
    }

    stopSpinner() {
        this.rotateIconTarget.classList.remove("rotate");
    }

    enterSearch(e) {
        if (this.noSearchValue() || e.key !== 'Enter') { return }
        this.drawTable()
    }

    search() {
        if (this.noSearchValue()) { return }
        this.drawTable()
    }

    reset() {
        if (this.noSearchValue()) { this.drawTable() }
    }

    noSearchValue() {
        return this.searchTarget.value === ""
    }

    drawTable() {
        this.startSpinner()
        $(this.renderTarget).DataTable().draw()
    }

    toggleRow(e) {
        if ($(e.target).hasClass('no-click')) return
        const tr = $(e.target).closest("tr");
        const row = $(this.renderTarget).DataTable().row(tr);
        tr.find("td:first-child").find("i").each(function () { $(this).toggleClass("d-none") });
        if (row.child.isShown()) {
            row.child.hide();
            tr.removeClass("shown");
        } else {
            row.child(row.data()[row.data().length - 1]).show()
            tr.addClass("shown");
        }
    }

    // Filters START
    initFilterInputs() {
        this.selectInputTargets.forEach(target => new mdb.Select(target, { "clearButton": false }));
        this.datepickerTargets.forEach(target => new mdb.Datepicker(target, { format: 'mm/dd/yyyy' }));
        $(this.monthYearInputTargets).find('select').each(function() {new mdb.Select(this, { "clearButton": false })});
    }

    applyFilters() {
        this.startSpinner()
        let filters = {}
        this.selectInputTargets.forEach(target => {
            filters[$(target).data('filter-column')] = $(target).val();
            $(this.filterPillsTarget).find(`div[data-filter-label="${$(target).data('filter-label')}"]`).remove()
            if ($(target).val() != '' && this.validPill($(target))) {
                $(this.filterPillsTarget).append(this.filterPill($(target).data('filter-label'), $(target).val(), $(target).find('option:selected').data('label')))
            }
        });
        this.datepickerTargets.forEach(target => {
            filters[$(target).data('filter-column')] = $(target).find('input').val();
            $(this.filterPillsTarget).find(`div[data-filter-label="${$(target).data('filter-label')}"]`).remove()
            if ($(target).find('input').val() != '' && this.validPill($(target), 'datetime')) {
                $(this.filterPillsTarget).append(this.filterPill($(target).data('filter-label'), $(target).find('input').val(), $(target).find('input').val()))
            }
        });
        $(this.monthYearInputTargets).each((i, container) => {
            const month = $(container).find('.month_year_range:eq(0)').val();
            const year = $(container).find('.month_year_range:eq(1)').val();
            if (month && year) {
                const filterLabel = $(container).data('filter-label');
                const filterColumn = $(container).data('filter-column');
                const filterValue = `${month}/${year}`;
                filters[`${filterColumn}_month_range`] = filterValue;
                $(this.filterPillsTarget).find(`div[data-filter-label="${filterLabel}"]`).remove();
                $(this.filterPillsTarget).append(this.filterPill(filterLabel, filterValue, filterValue));
            }
        });
        this.redraw(filters)
    }

    clearFilters() {
        this.selectInputTargets.forEach(target => mdb.Select.getInstance(target).setValue(""));
        this.datepickerTargets.forEach(target => {
            $(target).find('input').val('')
            $(target).find('input').removeClass('active')
        });
        this.monthYearInputTargets.forEach(target => {
            $(target).find('select').each(function(i, element) {mdb.Select.getInstance($(element)[0]).setValue("")})
        })
        $(this.filterPillsTarget).find('div').remove();
        this.applyFilters()
    }


    removeFilter(e) {
        let filters = $(`#${this.tableIdValue}_datatable_filters`)
        let elm = filters.find(`select[data-filter-label="${$(e.target.closest('div')).data('filter-label')}"]`)
        if (elm[0] == undefined) {
            elm = filters.find(`div[data-filter-label="${$(e.target.closest('div')).data('filter-label')}"] input`)
        }
        if (elm.length > 1){
            elm.each(function(i, element) { 
                $(element).removeClass('active')
                mdb.Select.getInstance($(element).closest('.form-outline').next('select')[0]).setValue("")
            })
        } else if (mdb.Select.getInstance(elm[0])){
            $(elm[0]).find('input').removeClass('active')
            mdb.Select.getInstance(elm[0]).setValue("")
        } else {
            $(elm[0]).removeClass('active')
            $(elm[0]).val('')
        }
        e.target.closest('div').remove()
        this.applyFilters()
    }

    filterPill(filterLabel, value, label) {
        return `<div class="badge badge-light rounded-pill ms-2 mt-1" style="cursor: pointer; margin-top: 11px !important;" data-filter-label="${filterLabel}" data-value="${value}" data-label="${label}">
            <span class="pt-1">
                ${this.capitalize(filterLabel)}: ${label}
                <i class="fa fa-close ms-2" data-action="click->cvm-datatable#removeFilter"></i>
            </span>
        </div>`
    }

    capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    validPill(target, type = 'select') {
        let filterPills = this.filterPillsTarget
        if ($(filterPills).find('div').length == 0) return true
        switch (type) {
            case 'select':
                if ($(filterPills).find(`div[data-filter-label="${$(target).data('filter-label')}"][data-value="${$(target).val()}"][data-label="${$(target).find('option:selected').data('label')}"]`).length == 0) return true
                return false
            case 'datetime':
                if ($(filterPills).find(`div[data-filter-label="${$(target).data('filter-label')}"][data-value="${$(target).find('input').val()}"][data-label="${$(target).find('input').val()}"]`).length == 0) return true
                return false
        }
    }
    // Filters END
}
