import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['table']
    static values = { 
      ajaxUrl: String, 
      columns: Array,
      options: Object
    }

    connect() {
      this.optionsValue = Object.keys(this.optionsValue).length > 0 ? this.optionsValue : this.defaultOptions()
      this.render()
    }

  render() {
    const controller = this
    const options = this.optionsValue

    $(controller.tableTarget).DataTable({
      ajax: controller.ajaxUrlValue,
      columns: controller.columnsValue,
      dom: '<"top">tr<"bottom"<"d-flex justify-content-between align-items-center border-top pt-3"<""i><"d-flex align-items-center"lp>>><"clear">',
      lengthMenu: [10, 30, 50, 100],
      orderCellsTop: false,
      ordering: options.ordering,
      pageLength: 50,
      processing: true,
      searching: options.searching,
      serverSide: true
    })
  }

  defaultOptions() {
    return {
      ordering: false,
      searching: false,
    }
  }
}
