
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
    }

    async refreshPage() {
        try {
            let tenants = $("#search-tenants").val();
            let automation_selection = $("#select-automation-type").val();
            let detection_type_selection = $("#select-detection-type").val();
            let date_time_range = $("#date_time_range").val();
            let url = '/mdr_soc_metrics_reload?' + new URLSearchParams({
                tenants: tenants,
                automation_selection: automation_selection,
                detection_type_selection: detection_type_selection,
                filter_timeframe: date_time_range,
                authenticity_token: window._token
            });
            console.log("URL", url)

            const response = await fetch(url, { method: 'POST' })

            if (response.ok) {
                const refreshedContent = await response.text()

                $("#mdr_soc_metrics_index").html(refreshedContent)
            } else {
                console.error('Failed to reload the content')
            }
        } catch (error) {
            console.error(error)
        }
    }}