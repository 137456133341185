import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['render']
  static values = { categories: Array, data: Array, dataName: String, pointerPath: String, title: String,
    yAxisTitle: String, color: String }

  connect() {
    new Highcharts.chart(this.renderTarget, this.chartOptions())
  }

  chartOptions() {
    let options = this.defaultOptions()

    if (this.hasCategoriesValue) { options.xAxis.categories = this.categoriesValue }
    if (this.hasTitleValue) { options.title.text = this.titleValue }
    if (this.hasYAxisTitle) { options.yAxis.title.text = this.yAxisTitle }

    if (this.hasPointerPathValue) {
      const pointerPath = this.pointerPathValue
      options.plotOptions.series.point = {
        events: {
          click: function() {
            location.href = `${pointerPath}${this.category}`
          }
        }
      }
    }

    if (this.hasDataValue) {
      let dataName = ""
      let colorName = "#f93943"
      if (this.hasDataNameValue) { dataName = this.dataNameValue }
      if (this.colorValue) { colorName = this.colorValue }
      options.series = [
        {
          color: colorName,
          data: this.dataValue,
          name: dataName,
          pointInterval: 1
        }
      ]
    }

    return options
  }

  defaultOptions() {
    return {
      chart: {
        backgroundColor: "#fff",
        type: "bar"
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      plotOptions: {
        series: {
          borderWidth: 0,
          pointWidth: 10,
          cursor: "pointer"
        }
      },
      series: [],
      title: { text: "" },
      tooltip: { pointFormat: "<b>{point.y}</b>" },
      xAxis: { categories: [] },
      yAxis: {
        tickInterval: 1,
        title: { text: "" }
      }
    }
  }
}