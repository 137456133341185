import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['render']
    static values = { data: Array, title: String }

    connect() {
        new Highcharts.chart(this.renderTarget, this.chartOptions())
    }

    chartOptions() {
        let options = this.defaultOptions()

        if (this.hasTitleValue && this.hasDataValue) { options.title.text = this.titleValue }
        if (this.hasDataValue) { options.series[0].data = this.dataValue }
        
        return options
    }

    defaultOptions() {
        return {
            chart: {
                backgroundColor: "#fff",
                type: "pie",
                events: {
                    render: function() {
                        let title = this.title;
                        title.attr({
                            y: this.plotTop + this.plotHeight / 2.5 + title.getBBox().height / 2
                        });
                    }
                }
            },
            credits: { enabled: false },
            exporting: { enabled: false },
            series: [{ 
                data: [],
                innerSize: '70%',
                showInLegend: true,
                dataLabels: {
                    align: 'right',
                    distance: 2,
                    formatter: function () {
                        return this.y + "%";
                    }
                } 
            }],
            title: {
                verticalAlign: 'middle',
                text: ""
            },
            lang: {
                noData: null // Disable "No data to display" message because title will always be there
            },
            tooltip: { enabled: false }
        }
    }
}