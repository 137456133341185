import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['datepicker', 'input', 'popover', 'select', 'tooltip']

  connect() {
    this.initializeDatepickers()
    this.initializeInputs()
    this.initializePopovers()
    this.initializeSelects()
    this.initializeTooltips()
  }

  initializeDatepickers() {
    if (this.hasDatepickerTarget) {
      this.datepickerTargets.forEach((datepickerTarget) => {
        new mdb.Datepicker(datepickerTarget, { format: 'mm/dd/yyyy' })
      })
    }
  }

  initializeInputs() {
    if (this.hasInputTarget) {
      this.inputTargets.forEach((formOutline) => {
        new mdb.Input(formOutline).init()
      })
    }
  }

  initializePopovers() {
    if (this.hasPopoverTarget) {
      this.popoverTargets.forEach((popoverTarget) => {
        new mdb.Popover(popoverTarget)
      })
    }
  }

  initializeSelects() {
    if (this.hasSelectTarget) {
      this.selectTargets.forEach((selectTarget) => {
        mdb.Select.getOrCreateInstance(selectTarget)
      })
    }
  }

  initializeTooltips() {
    if (this.hasTooltipTarget) {
      this.tooltipTargets.forEach((tooltipTarget) => {
        new mdb.Tooltip(tooltipTarget)
      })
    }
  }
}
