import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["section", "select"]

    connect() {}

    show() {
        const selectedValue = this.selectTarget.value

        this.sectionTargets.forEach(function(element) {
            if (element.dataset.id === selectedValue || selectedValue === "all") {
                if (element.classList.contains('d-none')) element.classList.remove('d-none')
            } else {
                if (!element.classList.contains('d-none')) element.classList.add('d-none')
            }
        })
    }
}