import { Controller } from '@hotwired/stimulus'

class SidebarController extends Controller {

  static targets = [
    'sidebar'
  ]
  setSidebarWidth() {
    if (window.innerWidth < 992 && !document.body.classList.contains('desktop-nav--slim')) {
      document.body.classList.add('desktop-nav--slim')
    }
  }
}

export default SidebarController
