import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {}

  async submit(event) {
    event.preventDefault()

    try {
      const response = await fetch(this.formTarget.action, {
        method: "POST",
        body: new FormData(this.formTarget),
        headers: { 'Accept': 'text/javascript' }
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const js = await response.text()
      if (js) {
        this.executeScript(js)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  executeScript(js) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.text = js
    document.head.appendChild(script).parentNode.removeChild(script)
  }
}
