import { Controller } from '@hotwired/stimulus'

class InfomationBlock extends Controller {
  static targets = ['content', 'moreInfoButton']

  connect() {
    if (this.contentTarget.offsetHeight > 58) {
      this.moreInfoButtonTarget.classList.remove('d-none')
    }
  }
}

export default InfomationBlock
