import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['render']
    static values = { options: Object }

    connect() {
        const dateOptions = this.hasOptionsValue ? this.optionsValue : {}
        $(this.renderTarget).daterangepicker(dateOptions)
        this.resetMdbOnApply()
    }

    resetMdbOnApply() {
        const mdbInput = this.renderTarget.parentElement
        $(this.renderTarget).bind('apply.daterangepicker', function() {
            new mdb.Input(mdbInput)
        })
    }
}