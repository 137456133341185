import { Controller } from "@hotwired/stimulus"
import cvm_datatable_controller from "./cvm_datatable_controller";

export default class extends Controller {
  static targets = ["form", "submit", "error"];

  connect() {
    const form = this.formTarget
    const submitButton = $(form).find('button[type="submit"]');
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      if (tinyMCE) tinyMCE.triggerSave();
      const formData = new FormData(form);
      this.errorTarget.classList.add('hide');
      for (let [name, value] of formData.entries()) {
        if (value === '') {
          this.errorTarget.classList.remove('hide');
          return;
        }
      }

      submitButton.prop("disabled", true);
      submitButton[0].classList.add('disabled')
      let spinner = $(submitButton).find('#spinner')[0]
      if (spinner) {
        spinner.classList.remove('hide')
      }

      fetch(form.action, {
        method: form.method,
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      })
        .then(response => response.json())
        .then(() => {
          form.reset();
          $(form.closest('.modal')).modal('hide');
          submitButton.prop("disabled", false);
          submitButton[0].classList.remove('disabled')
          let spinner = $(submitButton).find('#spinner')[0]
          if (spinner) {
            spinner.classList.add('hide')
          }
          if ($("#historicalReportsModal").length > 0) {
              $("#oem_historical_report_file_table").DataTable().draw()
          }
          })
        .catch(error => {
          console.error(error);
          submitButton.prop("disabled", false);
          submitButton[0].classList.remove('disabled')
          if (spinner) {
            spinner.classList.add('hide')
          }
        });
    });
  }
}
