import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tab']

  connect() { }

  switch(event) {
    const triggerEl = this.tabTargets.filter(tab => tab.href.includes(event.target.value))[0]
    event.preventDefault()
    new mdb.Tab(triggerEl).show()
  }
}