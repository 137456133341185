import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets= ['input', 'change'];
    static values = { 
        attribute: String,
        prependAttribute: String,
        appendAttribute: String,
    }

    connect() {
    }

    changeAttribute() {
        this.changeTarget.setAttribute(this.attributeValue, this.prependAttributeValue + this.inputTarget.value + this.appendAttributeValue);
    }
}
