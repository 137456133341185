import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "datepicker", "chip", "wrapper"]
  static values = {
    table: String,
    dataPath: String,
    dataPathQuery: String
  }

  connect() {
    this.selectInstances = []
    this.datepickerInstances = []
    this.initSelects()
  }

  initSelects() {
    this.selectTargets.forEach(select => {
      const mdbInstance = new mdb.Select(select)
      this.selectInstances.push({ element: select, instance: mdbInstance })

      select.addEventListener('valueChange.mdb.select', (event) => {
        this.updateChip(select, 'select')
        this.fetchData()
        this.closeDropdown(mdbInstance)
      })
    })

    this.datepickerTargets.forEach(datepicker => {
      let dp = $(datepicker).daterangepicker(this.datepickerOptions())
      this.datepickerInstances.push({ element: datepicker })
      dp.on("change", (event) => {
        this.updateChip(datepicker, 'datepicker')
        this.sortChips()
      })
    })
  }

  updateChip(element, type) {
    const chipWrapper = element.closest('.dt-filter-chips-wrapper')
    const chip = chipWrapper.querySelector('.dt-chip-filter')
    const closeIcon = chip.querySelector('.fa-times')
    const checkIcon = chip.querySelector('.fa-check')
    const textSpan = chip.querySelector('.text-chip')

    const originalText = textSpan.dataset.originalText || textSpan.textContent
    let selectedValue = ''
    if (type === 'select') {
      const selectedOptions = Array.from(element.selectedOptions).map(option => option.text)
      selectedValue = selectedOptions.join(', ')
    } else if (type === 'datepicker') {
      selectedValue = element ? element.value : ''
    }

    if (selectedValue) {
      closeIcon.classList.remove('d-none')
      checkIcon.classList.remove('d-none')
      chip.classList.add('dt-chip-filter-selected')
      chip.dataset.active = 'true'

      if (!textSpan.dataset.originalText) {
        textSpan.dataset.originalText = originalText
      }

      textSpan.textContent = `${originalText}: ${selectedValue}`
    } else {
      closeIcon.classList.add('d-none')
      checkIcon.classList.add('d-none')
      chip.classList.remove('dt-chip-filter-selected')
      chip.dataset.active = 'false'
      textSpan.textContent = originalText
    }
  }

  clearFilter(event) {
    const chipWrapper = event.target.closest('.dt-filter-chips-wrapper')
    const select = chipWrapper.querySelector('select')
    const datepickerWrapper = chipWrapper.querySelector('.dt-fc-datepicker')
    const datepickerInput = datepickerWrapper ? datepickerWrapper : null

    if (select) {
      const selectInstance = this.selectInstances.find(item => item.element === select)
      if (selectInstance && selectInstance.instance) {
        select.querySelectorAll('option:checked').forEach(option => {
          option.selected = false
        })

        select.dispatchEvent(new Event('change'))
        selectInstance.instance.setValue('')
        this.updateChip(select, 'select')
      }
    } else if (datepickerInput) {
      datepickerInput.value = ''
      this.updateChip(datepickerInput, 'datepicker')
    }
    this.fetchData()
    this.sortChips()
  }

  resetAll() {
    this.selectInstances.forEach(({ element, instance }) => {
      element.querySelectorAll('option:checked').forEach(option => {
        option.selected = false
      })
      element.dispatchEvent(new Event('change'))
      instance.setValue('')
      this.updateChip(element, 'select')
    })

    this.datepickerInstances.forEach(({ element, instance }) => {
      element.value = ''
      this.updateChip(element, 'datepicker')
    })

    if (this.dataPathQueryValue) {
      $(this.tableValue).DataTable().ajax.url(`${this.dataPathValue}${this.dataPathQueryValue}`).load()
    } else {
      $(this.tableValue).DataTable().ajax.url(`${this.dataPathValue}`).load()
    }

    this.sortChips()
  }

  fetchData() {
    const params = this.getFiltersData()

    $(this.tableValue).DataTable().ajax.url(`${this.dataPathValue}${this.dataPathQueryValue}&${params}`).load()

    this.sortChips()
  }

  sortChips() {
    const container = this.wrapperTarget
    const wrappers = Array.from(container.children)

    wrappers.sort((a, b) => {
      const chipA = a.querySelector('.dt-chip-filter')
      const chipB = b.querySelector('.dt-chip-filter')
      const activeA = chipA.dataset.active === 'true'
      const activeB = chipB.dataset.active === 'true'
      const titleA = chipA.querySelector('.text-chip').dataset.originalText || chipA.querySelector('.text-chip').textContent
      const titleB = chipB.querySelector('.text-chip').dataset.originalText || chipB.querySelector('.text-chip').textContent

      if (activeA === activeB) {
        return titleA.localeCompare(titleB)
      }
      return activeA ? -1 : 1
    })

    wrappers.forEach(wrapper => container.appendChild(wrapper))
  }

  closeDropdown(mdbInstance) {
    if (mdbInstance && typeof mdbInstance.close === 'function') {
      mdbInstance.close()
    }
  }

  getFiltersData() {
    const filtersData = []

    this.selectTargets.forEach(select => {
      const selectedOptions = Array.from(select.selectedOptions).map(option => option.text)
      if (selectedOptions.length > 0) {
        const columnTitle = select.closest('.dt-filter-chips-wrapper').querySelector('.text-chip').dataset.title
        filtersData.push({
          data: columnTitle,
          values: selectedOptions
        })
      }
    })

    this.datepickerTargets.forEach(datepickerInput => {
      const dateRange = datepickerInput.value
      const columnTitle = datepickerInput.closest('.dt-filter-chips-wrapper').querySelector('.text-chip').dataset.title
      const isActive = datepickerInput.closest('.dt-filter-chips-wrapper').querySelector('.dt-chip-filter').dataset.active
      if (isActive == "true") {
        filtersData.push({
          data: columnTitle,
          values: [dateRange]
        })
      }
    })

    const reformattedFilters = filtersData.reduce((acc, filter) => {
      acc[filter.data] = filter.values
      return acc
    }, {})

    return Object.entries(reformattedFilters)      
      .map(([key, value]) => `filters[${encodeURI(key)}]=${encodeURI(value)}`)
      .join('&');
  }

  datepickerOptions() {
    return {
      timePicker: true,
      timePicker24Hour: true,
      timePickerIncrement: 1,
      locale: { format: 'MM/DD/YYYY' },
      showDropdowns: true,
      ranges: {
        'Today': [
          new Date(new Date().setHours(new Date().getHours() - 25)).toLocaleString('en-US', { hour12: false }),
          new Date().toLocaleString('en-US', { hour12: false })
        ],
        'Yesterday': [
          new Date(new Date().setHours(new Date().getHours() - 49)).toLocaleString('en-US', { hour12: false }),
          new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleString('en-US', { hour12: false })
        ],
        'Last 7 Days': [
          new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleString('en-US', { hour12: false }),
          new Date().toLocaleString('en-US', { hour12: false })
        ],
        'Last 30 Days': [
          new Date(new Date().setDate(new Date().getDate() - 30)).toLocaleString('en-US', { hour12: false }),
          new Date().toLocaleString('en-US', { hour12: false })
        ],
        'This Month': [
          new Date(new Date().setDate(1)).toLocaleString('en-US', { hour12: false }),
          new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).toLocaleString('en-US', { hour12: false })
        ],
        'Last Quarter': [
          new Date(new Date().setMonth(new Date().getMonth() - 3)).toLocaleString('en-US', { hour12: false }),
          new Date().toLocaleString('en-US', { hour12: false })
        ]
      },
      alwaysShowCalendars: true
    }
  }
}
