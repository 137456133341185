import { Controller } from '@hotwired/stimulus'

class CloseMobileSidebarController extends Controller {

  close() {
    document.body.classList.toggle('mobile-nav--active')
    document.querySelector('.app__main').classList.toggle('pe-none')
    document.querySelector('.mobile-overlay').remove()
    document.querySelector('.topbar__logo').classList.remove('d-none')
  }
}

export default CloseMobileSidebarController