import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        graphId: String,
        data: Array,
        forwardLink: String,
        klass: String
    }

    connect() {
        this.loadGraph()
    }

    loadGraph() {
        let dispatch = this.dispatchFilter
        let linkValue = this.forwardLinkValue
        let klass = this.klassValue
        Highcharts.chart(this.graphIdValue, {
            chart: {
                type: 'packedbubble',
            },
            legend: {
                enabled: false
            },
            title: {
                text: ''
            },
            plotOptions: {
                packedbubble: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}',
                        style: {
                            color: 'black',
                            textOutline: 'none',
                            fontWeight: 'normal'
                        }
                    },
                    minPointSize: 5,
                    minSize: 50,
                    maxSize: 150
                },
                series: {
                    cursor: 'pointer',
                    events: {
                        click: (data) => {
                            dispatch(linkValue, data, klass)
                        }
                    }
                }
            },
            credits: { enabled: false },
            colors: ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#dc3545', '#fd7e14', '#ffc107', '#28a745', '#20c997', '#17a2b8', '#6c757d', '#343a40', '#28a745'],
            series: this.dataValue
        });
    }

    dispatchFilter(linkValue, data, klass) {
        $(`.${klass.replace('OEM::','')}_datatable_search`).val('')
        $(`.${klass.replace('OEM::','')}_datatable_search`)[0].classList.remove('active')
        $(`a[data-id=${linkValue}]`)[0].click();
        window.scrollTo(0, 0);
        switch (klass) {
            case 'OEM::Vulnerability':
                $('#oem_vulnerability_table_datatable_filters button.text-primary.mt-1')[0].click()
                var value = $(`#Category_select_filter option:contains("${data.point.name}")`).val();
                mdb.Select.getInstance($('#Category_select_filter')[0]).setValue(value);
                mdb.Select.getInstance($('#Status_select_filter')[0]).setValue('Open'); 
                $('#oem_vulnerability_table_datatable_filters button.btn.btn-primary')[0].click()
                break
            case 'OEM::PatchDetail':
                $('#oem_patch_detail_table_datatable_filters button.text-primary.mt-1')[0].click()
                mdb.Select.getInstance($('#oem_patch_detail_table_datatable_filters #Category_select_filter')[0]).setValue(data.point.name);
                $('#oem_patch_detail_table_datatable_filters button.btn.btn-primary')[0].click()
                break
        }
    }
}
