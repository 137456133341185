import { Controller } from "@hotwired/stimulus"

class PoolLicensingController extends Controller {
  static targets = ['placeholder']
  static values = {
    parnterId: { type: String, default: '' },
    activeRow: { type: Object, default: {} }
  }

  connect() {
    this.element.addEventListener('show.mdb.modal', () => {
      $('#partner-info-table').on('click', '.dropdown-item', e => {
        this.placeholderTarget.textContent = e.target.dataset.dropdownName

        this.partnerIdValue = e.target.dataset.dropdownId
        this.activeRow = $(e.currentTarget).closest('tr')
      })
    })

  }

  async enablePoolLicensing() {
    const modal = mdb.Modal.getInstance(this.element)
    const poolingFeedback = document.getElementById('enable-pool-alert-container')
    const partnerTable = $('#partner-info-table').DataTable()

    try {
      const response = await fetch(`/partner_licenses/${this.partnerIdValue}?partner_id=${this.partnerIdValue}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
      })

      const data = await response.json()

      poolingFeedback.innerHTML = data.html;
      $(poolingFeedback).fadeIn('normal', function () {
        $(this).delay(5000).fadeOut();
      });

      modal.hide()

      const rowIndex = partnerTable.row(this.activeRow).index()
      partnerTable.row(rowIndex).draw()

    } catch (e) {
      console.error(e)
    }
  }
}

export default PoolLicensingController
