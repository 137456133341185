import { Controller } from "@hotwired/stimulus"

class ChangelogController extends Controller {
  static values = {
    licenseId: String,
    allocationId: String,
    allocationType: String,

  }

  connect() {
    this.element.addEventListener('show.bs.collapse', e => {
      this.fetchAllocationLogs()
    })
  }

  async fetchAllocationLogs() {
    try {
      const response = await fetch(`/license_allocation_logs/${this.licenseIdValue}?allocation_id=${this.allocationIdValue}&allocation_type=${this.allocationTypeValue}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
      })

      const logs = await response.json()

      return this.changeLogtemplate(logs)
    } catch (e) {
      console.error('Error fetching changelog: ', e);
    }
  }

  changeLogtemplate(logs) {
    let logTemplate = ''

    if (!logs.length) {
      logTemplate = "<div><h3>No logs available.</h3>"
    } else {
      logTemplate = logs.map(log => (`
          <div class="border-bottom ps-5 py-2">
            <h6 class="mb-2">${new Date(log.created_at).toLocaleString('en-us', { weekday: 'long' })}</h5>
            <h6 class="mb-2">${this.#formatDate(log.created_at)}</h5>
            <p class="mb-1 fst-italic">${log.process} ${log.allocated} Licenses.</p>
            ${log.note ? `<p class="mb-1 fst-italic"><b>Note:</b> ${log.note}</p>` : ''}
            ${log.username ? `<p class="mb-1"><i class="fa-regular fa-circle-user"></i> ${log.username}</p>` : ''}
          </div>
        `)
      ).join('')
    }

    this.element.innerHTML = logTemplate
  }

  #formatDate(date) {
    return new Date(date).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })
  }
}

export default ChangelogController
