export const contentReveal = (id, table) => { 
  return `
    <div      
      class="pt-1 ps-3 filter-fetch-container"
      role="tabpanel"
      data-controller="content-loader"
      data-content-loader-url-value="/render_filter_chips?partner_id=${id}&table=${table}"
      data-content-loader-load-scripts-value="true"
      data-content-loader-lazy-loading-value=""
    >    
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    </div>
  `
} 