import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["graph", "loader"];

    static values = { data: String, options: Object };

    connect() {
        this.optionsValue = Object.keys(this.optionsValue).length > 0 ? this.optionsValue : this.defaultOptions()
        $(this.loaderTarget).hide()
        Highcharts.chart(this.graphTarget, this.chartConfig());
    }

    chartConfig() {
        const data = JSON.parse(this.dataValue)
        const options = this.optionsValue
        return {
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: 'Count'
                },
            },
            xAxis: {
                type: 'datetime',
                ...(options.xAxisDaysOfWeek ? {
                    labels: {
                        formatter: function () {
                            return Highcharts.dateFormat('%A', this.value);
                        },
                        style: {
                            fontSize: '12px',
                            color: '#333'
                        }
                    },
                    tickInterval: 24 * 3600 * 1000,
                    tickPositioner: function () {
                        const positions = [];
                        const dataArray = data[0].data;
                        
                        dataArray.forEach((point) => {
                            positions.push(point[0]);
                        });
    
                        return positions;
                    }
                } : {
                    dateTimeLabelFormats: {
                        month: '%b'
                    },
                }
                ),
                startOnTick: false,
                endOnTick: false,
                ...(options.xAxisLine && {
                    gridLineDashStyle: 'dash',
                    gridLineWidth: 1
                })
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    ...(options.showValuesOnLines && {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.y;
                            },
                            style: {
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#333'
                            }
                        }
                    })
                }
            },
            series: data,
            responsive: {
                rules: [{
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        }

    }

    defaultOptions() {
        return {
            xAxisLine: false,
            xAxisDaysOfWeek: false,
            showValuesOnLines: false
        }

    }
}
