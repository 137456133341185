import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'modal',
    'item',
    'modalOpener',
    "executeButton",
    "datatable",
    "selectAllToggle",
    "singleExemptButton",
    "progressBanner",
    "successBanner",
    "errorBanner"
  ];

  selectedItems = {};
  datatableItem = null;
  alternateTablesToReload = [];
  static values = {
    executionUrl: String,
    selectedItemsKey: String,
    alternateTableIdsToReload: Array,
  }

  initialize(){
    // Used for reloading the table to show updates
    this.datatableItem = $(this.datatableTarget).DataTable();
    try{
      let thisController = this;
      this.alternateTableIdsToReloadValue.forEach((tableId) => {
        thisController.alternateTablesToReload.push($(`#${tableId}`).DataTable());
      });
    }catch(err){
    }
  }

  // Adds or removes the selected item by the ID dataset attribute from the currently selected  items
  toggleItem(event) {
    let element = event.currentTarget;
    
    if (element.checked){
      this.selectedItems[element.dataset.id] = JSON.parse(element.dataset.rowdata)
    }else{
      delete this.selectedItems[element.dataset.id];
    }

    if(this.hasItems()){
      this.modalOpenerTargets.forEach((el, ind) => {
        $(el).attr("disabled", false);
        $(el).removeClass("disabled");
      });
    }else{
      this.modalOpenerTargets.forEach((el, ind) => {
        $(el).attr("disabled", true);
        $(el).addClass("disabled");
      });
    }
  }

  clear(){
    this.selectedItems = {};
    this.modalOpenerTargets.forEach((el, ind) => {
      $(el).attr("disabled", true);
      $(el).addClass("disabled");
    });

    this.itemTargets.forEach((element) => {
      $(element).prop("checked", false);
    })

    $(this.selectAllToggleTarget).prop("checked", false);
  }

  updateModalDisplay(){
    let tableBody = $(this.modalTarget).find("tbody");
    tableBody.empty();
    if(this.hasItems()){
        for(let id in this.selectedItems){
            let tr = $("<tr>");
            this.selectedItems[id].forEach((dataColumn) => {
              let td = $("<td>");
              td.text(dataColumn);
              tr.append(td);
            });
            tableBody.append(tr);
        }
    }
  }

  disableExecuteButton(){
    $(this.executeButtonTarget).prop("disabled", true);
  }

  enableExecuteButton(){
    $(this.executeButtonTarget).prop("disabled", false);
  }

  hasItems(){
    return Object.keys(this.selectedItems).length > 0;
  }

  hideModal(){
    let modal = mdb.Modal.getInstance(this.modalTarget);
    modal.hide();
  }

  itemTargetConnected(element){
    if(this.selectedItems[element.dataset.id] !== undefined){
      $(element).prop("checked", true);
    }
  }

  showProgressBanner(){
    $(this.progressBannerTarget).removeClass("d-none");
  }

  hideProgressBanner(){
    $(this.progressBannerTarget).addClass("d-none");
  }

  flashSuccessBanner(){
    let thisController = this;
    $(this.successBannerTarget).removeClass("d-none");
    setTimeout(() => {
      $(thisController.successBannerTarget).addClass("d-none");
    }, 8000);
  }
  
  flashErrorBanner(){
    let thisController = this;
    $(this.errorBannerTarget).removeClass("d-none");
    setTimeout(() => {
      $(thisController.errorBannerTarget).addClass("d-none");
    }, 8000);
  }

  toggleSelectAll(event){
    if(event.currentTarget.checked){
      this.itemTargets.forEach((element) => {
        $(element).prop("checked", true);
        this.selectedItems[element.dataset.id] = JSON.parse(element.dataset.rowdata)
      })
    }else{
      this.itemTargets.forEach((element) => {
        $(element).prop("checked", false);
        delete this.selectedItems[element.dataset.id];
      })
    }

    if(this.hasItems()){
      this.modalOpenerTargets.forEach((el, ind) => {
        $(el).attr("disabled", false);
        $(el).removeClass("disabled");
      });
    }else{
      this.modalOpenerTargets.forEach((el, ind) => {
        $(el).attr("disabled", true);
        $(el).addClass("disabled");
      });
    }
  }
}
