import { Controller } from "@hotwired/stimulus"

/**
 * CsvController
 *
 * This is a Stimulus controller for handling CSV related operations.
 *  You can download via a URL, with an optional ID.
 *
 * Targets:
 * - csvLink: This target is used to reference the CSV link element in the DOM.
 *
 * Values:
 * - url: This value is used to store the URL for the CSV download.
 * - csvId: This value is used to store the ID of the CSV.
 *
 * Methods:
 * - download(): This method is used to handle the CSV download operation. If the csvId value is null,
 *   it will retrieve the URL and populate the target.
 *   If the csvId value is not null, it will get the csv_id from the DOM element with the ID stored in csvId,
 *   append it to the URL.
 *
 * Usage:
 * To use this controller, you need to add data attributes to your HTML elements. Here's an example:
 *
 * <div class='dropdown' data-controller='csv'
 *      data-csv-url-value='partner_tenant_download_csv'
 *      data-csv-csv-id-value='tenant_level_partner_id'>
 * </div>
 *
 * <div class='dropdown' data-controller='csv'
 *      data-csv-url-value='partner_tenant_download_csv'
 * </div>
 *
 * In this example, 'partner_tenant_download_csv' is the URL for the CSV download and 'tenant_level_partner_id' is the ID of the CSV.
 */
export default class extends Controller {
  static targets = ['csvLink']
  static values = { url: String, csvId: String }

  download() {
    if (this.csvIdValue) {
      const csv_id = $('#' + this.csvIdValue).val();
      window.location.href = this.urlValue + '?id=' + csv_id
    } else {
      window.location.href = this.urlValue
    }
  }
}
