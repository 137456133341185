import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["chart", "loader"];
    static values = { data: Array, title: String };

    connect() {
        $(this.loaderTarget).hide();
        Highcharts.chart(this.chartTarget.id, this.chartConfig())
    }

    chartConfig() {
        const data = this.dataValue
        const title = this.titleValue
        const categories = data.map(item => item.name)
        return (
            {
                chart: {
                    type: 'column',
                },
                credits: {
                    enabled: false,
                },
                exporting: {
                    enabled: false,
                },
                title: {
                    text: ''
                },
                xAxis: {
                    labels: {
                        enabled: false,
                        align: "right",
                        style: {
                            textOverflow: 'none',
                            whiteSpace: 'nowrap'
                        },
                    },
                    categories: categories,
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: title
                    },
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px"></span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td> <td style="padding:0"><b>{point.y}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        pointWidth: 80,
                    },
                    bar: {
                        dataLabels: {
                            padding: 0,
                        }
                    }
                },
                series: data
            }
        )
    }
}
