import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["graph", "loader"]

  static values = { data: Array }

  connect() {
    this.hideLoader()
    this.render()
  }

  hideLoader() {
    if (this.hasLoaderTarget) {
      $(this.loaderTarget).hide()
    }
  }

  render() {
    Highcharts.chart(this.graphTarget, {
      credits: { enabled: false },
      exporting: { enabled: false },
      series: this.dataValue,
      title: { text: '' },
      xAxis: {
        dateTimeLabelFormats: { month: '%b' },
        gridLineDashStyle: 'dash',
        gridLineWidth: 1,
        type: 'datetime'
      },
      yAxis: {
        title: { text: 'Count' },
      }
    })
  }
}
