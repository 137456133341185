import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["chart", "loader"];
    static values = { data: String };

    connect() {
        Highcharts.chart(this.chartTarget.id, this.chartConfig());
        $(this.loaderTarget).hide();
    }

    chartConfig() {
        const data = JSON.parse(this.dataValue)
        return (
            {
                chart: {
                    type: 'packedbubble',
                },
                legend: {
                    enabled: false
                },
                exporting: {
                    enabled: false,
                },
                title: {
                    text: ''
                },
                plotOptions: {
                    packedbubble: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}',
                            style: {
                                color: 'black',
                                textOutline: 'none',
                                fontWeight: 'normal'
                            }
                        },
                        minPointSize: 5,
                        minSize: 50,
                        maxSize: 150
                    }
                },
                credits: { enabled: false },
                colors: ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#dc3545', '#fd7e14', '#ffc107', '#28a745', '#20c997', '#17a2b8', '#6c757d', '#343a40', '#28a745'],
                series: data
            }
        )
    }
}
