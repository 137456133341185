import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['render', 'spinner', "errorDisplay"]
    static values = {
        data: Array,
        dataAjaxSource: {type: String, default: ""},
    }

    // These are used for getting tooltip formats for specific AJAX responses
    tooltipFormats = {
        default: {
            pointFormat: 'IP: {point.ip_address}<br>' +
                         'Country: {point.country}<br>' +
                         'State: {point.region}<br>' +
                         'City: {point.city}<br>' +
                         'Lat: {point.lat}<br>' +
                         'Lon: {point.lon}<br>' +
                         'Events: {point.z}'
        }
    };

    chartTopLevelDefaults = {
        boost: {
            useGPUTranslations: true,
            usePreAllocated: true,
            allowForce: true
        },
        chart: {
            borderWidth: 0,
            map: 'custom/world-highres3',
            height: '65%'
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        legend: {
            layout: 'vertical',
            verticalAlign: 'bottom',
            align: 'left',
            floating: true,
            itemStyle: { color: '#4F4F4F', fontSize: '16px' }
        },
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                style: { color: "#fff" },
                align: "left",
                theme: {
                    fill: '#332d2d',
                    'stroke-width': 0,
                    stroke: '',
                    r: 4,
                    states: {
                        hover: { fill: '#332d2d' },
                        select: {
                            stroke: '',
                            fill: '#332d2d'
                        }
                    }
                }
            }
        },
    }

    baseMapSeries = [
        {
            borderColor: '#546E7A',
            enableMouseTracking: false,
            name: 'Basemap',
            nullColor: '#141A22',
            showInLegend: false,
        },
        {
            enableMouseTracking: false,
            name: 'Separators',
            nullColor: '#2AE052',
            showInLegend: false,
            type: 'mapline'
        },
    ];

    connect() {
        this.render()
    }

    render() {
        // AJAX source has very specific data requirements since we need to generalize this
        if (this.dataAjaxSourceValue !== ""){
            let thisController = this;
            $(this.spinnerTarget).removeClass("d-none");
            $.ajax({
                method: "GET",
                url: this.dataAjaxSourceValue,
                success: (data) => {
                    console.log(data);
                    $(thisController.spinnerTarget).addClass("d-none");
                    thisController.createChartAjax({
                        seriesData: data.seriesData,
                        tooltipFormatterKey: data.tooltipFormatterKey
                    });
                },
                error: () => {
                    $(thisController.spinnerTarget).addClass("d-none");
                    $(thisController.errorDisplayTarget).removeClass("d-none");
                }
            })
        }else{
            // We have a very simple default value available that inserts JSON blob data into a single series value
            const sourceData = this.dataValue
            this.createChart(sourceData);
        }
    }

    // Backwards compatible create chart method for old charts not using lazy load ajax and new interface requirements
    createChart(sourceData){
        new Highcharts.mapChart(this.renderTarget, {
            ...this.chartTopLevelDefaults,
            series: [
                ...this.baseMapSeries,
                {
                    color: '#D6F0E0',
                    dataLabels: { enabled: false },
                    joinBy: ['iso-a2', 'code'],
                    maxSize: 100,
                    minSize: 25,
                    name: 'Event Source',
                    type: 'mapbubble',
                    data: sourceData
                }
            ],
            title: { text: '' },
            tooltip: this.tooltipFormats.default
        });
    }

    // This function provides a more complex method of building a chart from an AJAX source
    // Series must be a Highcharts map data series array definition, we may want to create an interface for common data series types
    // at some point instead of requiring the AJAX response to define the series values
    createChartAjax({seriesData, tooltipFormatterKey}){

        let opts = {
            ...this.chartTopLevelDefaults,
            series: [
                ...this.baseMapSeries,
                ...seriesData
            ],
            title: { text: '' },
            tooltip: tooltipFormatterKey && this.tooltipFormats[tooltipFormatterKey] ? this.tooltipFormats[tooltipFormatterKey] : this.tooltipFormats.default
        };

        console.log(opts);

        new Highcharts.mapChart(this.renderTarget, opts);
    }
}