import { Controller } from '@hotwired/stimulus'

class IntegrationSyncController extends Controller {
  static targets = [
    'getProjectsForm',
    'projectsResponse',
    'spinnerPlaceholder',
    'jiraToGenericJiraStatus',
    'jiraToGenericGenericStatus',
    'jiraFromGenericJiraStatus',
    'jiraFromGenericGenericStatus',
    'jiraToAdluminJiraPriorities',
    'jiraToAdluminAdluminPriorities',
    'adluminToJiraAdluminPriorities',
    'adluminToJiraJiraPriorities',
    'externaljiraIssueTypeId',
    'externaljiraToGenericStatusJson',
    'externaljiraStatusFromGenericStatusJson',
    'externaljiraToJiraPriorityJson',
    'jiraToExternalJiraPriorityJson',
    'submitButton'
  ]

  static values = {
    jiraToGenericStatus: { type: Object, default: {} },
    jiraFromGenericStatus: { type: Object, default: {} },
    jiraToAdluminPriority: { type: Object, default: {} },
    adluminToJiraPriority: { type: Object, default: {} },
  }

  mapValues({ params }) {
    switch (params.form) {
      case 'jiraToGenericJiraStatus':
        Object.assign(this.jiraToGenericStatusValue, {
          [this.jiraToGenericJiraStatusTarget.value]: this.jiraToGenericGenericStatusTarget.options[this.jiraToGenericGenericStatusTarget.selectedIndex].text
        })
        this.#setMappedText(this.jiraToGenericJiraStatusTarget, this.jiraToGenericGenericStatusTarget)
        this.#setSyncValue(this.externaljiraToGenericStatusJsonTarget, this.jiraToGenericStatusValue)
        break
      case 'jiraFromGenericGenericStatus':
        Object.assign(this.jiraFromGenericStatusValue, {
          [this.jiraFromGenericJiraStatusTarget.options[this.jiraFromGenericJiraStatusTarget.selectedIndex].text]: this.jiraFromGenericGenericStatusTarget.value
        })
        this.#setMappedText(this.jiraFromGenericJiraStatusTarget, this.jiraFromGenericGenericStatusTarget)
        this.#setSyncValue(this.externaljiraStatusFromGenericStatusJsonTarget, this.jiraFromGenericStatusValue)
        break
      case 'jiraToAdluminAdluminPriorities':
        Object.assign(this.jiraToAdluminPriorityValue, {
          [this.jiraToAdluminJiraPrioritiesTarget.value]: this.jiraToAdluminAdluminPrioritiesTarget.value
        })
        this.#setMappedText(this.jiraToAdluminJiraPrioritiesTarget, this.jiraToAdluminAdluminPrioritiesTarget)
        this.#setSyncValue(this.externaljiraToJiraPriorityJsonTarget, this.jiraToAdluminPriorityValue)
        break
      case 'adluminToJiraJiraPriorities':
        Object.assign(this.adluminToJiraPriorityValue, {
          [this.adluminToJiraAdluminPrioritiesTarget.value]: this.adluminToJiraJiraPrioritiesTarget.value
        })
        this.#setMappedText(this.adluminToJiraAdluminPrioritiesTarget, this.adluminToJiraJiraPrioritiesTarget)
        this.#setSyncValue(this.jiraToExternalJiraPriorityJsonTarget, this.adluminToJiraPriorityValue)
        break
      default:
        return
    }
    this.checkHiddenFieldValuePresence()
  }

  formSubmit() {
    this.spinnerPlaceholderTarget.innerHTML = this.#formSpinner()
  }

  checkHiddenFieldValuePresence() {
    const validated = this.#hiddenFields().findIndex(field => field.value == '')
    if (validated === -1) {
      this.submitButtonTarget.classList.remove('disabled')
      this.submitButtonTarget.disabled = false
    }
  }

  #setSyncValue(hiddenField, obj) {
    hiddenField.value = JSON.stringify(obj)
  }

  #setMappedText(value, mappedValue) {
    value.options[value.selectedIndex].dataset.mdbSecondaryText = `Mapped to: ${mappedValue.options[mappedValue.selectedIndex].text}`

    if ((value.options['selectedIndex'] + 1) < value.options.length) {
      value.options[value.selectedIndex + 1].selected = 'selected'
    }
  }

  #formSpinner() {
    return (`
      <div class="d-flex mt-4 justify-content-center align-items-center">
        <div class="spinner-border me-3" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <h2>Fetching from Jira.</h2>
      </div>
    `)
  }

  #hiddenFields() {
    return (
      [
        this.externaljiraIssueTypeIdTarget,
        this.externaljiraToGenericStatusJsonTarget,
        this.externaljiraStatusFromGenericStatusJsonTarget,
        this.externaljiraToJiraPriorityJsonTarget,
        this.jiraToExternalJiraPriorityJsonTarget
      ]
    )
  }
}

export default IntegrationSyncController
