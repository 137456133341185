import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['table']
    static values = { ajaxUrl: String, columns: Array }

    connect() {
      this.render()
    }

  render() {
    const controller = this

    $(controller.tableTarget).DataTable({
      ajax: controller.ajaxUrlValue,
      columns: controller.columnsValue,
      info: false,
      language: {
        loadingRecords: "Loading...&nbsp;",
        processing: "Loading events...",
        search: "",
        zeroRecords: "No events found."
      },
      orderCellsTop: false,
      ordering: false,
      paging: false,
      processing: true,
      searching: false,
      serverSide: true
    })
  }
}
