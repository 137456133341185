import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]
  static values = {
    initialized: Boolean
  }

  connect() {
    console.warn("DataSourceSelect controller connected")
    // Check if selectize controller exists on same element
    if (!this.element.getAttribute('data-controller').includes('selectize')) {
      console.error("Selectize controller not found on element")
      return
    }
  }

  // Called when selectize finishes initializing
  selectizeInitialized(event) {
    console.log("Selectize initialization detected")
    const selectize = event.detail.selectize
    if (selectize) {
      selectize.on('change', (value) => {
        const selectedOption = selectize.options[value]
        const mustQuery = selectedOption ? selectedOption.must_query : []
        const mustQueryString = JSON.stringify(mustQuery)
        console.log('Setting must_query to:', mustQueryString)
        document.getElementById('must_query').value = mustQueryString
      })
    }
  }
} 