import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        graphId: String,
        data: Object,
        forwardLink: String,
        klass: String
    }

    connect() {
        this.loadGraph()
    }

    loadGraph() {
        let dispatch = this.dispatchFilter
        let linkValue = this.forwardLinkValue
        let klass = this.klassValue
        Highcharts.chart(this.graphIdValue, {
            title: {
                text: null
            },
            yAxis: {
                title: {
                    text: null
                },
            },
            xAxis: {
                categories: this.dataValue.categories,
            },
            credits: { enabled: false },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    cursor: 'pointer',
                    events: {
                        click: (data) => {
                            dispatch(linkValue, data, klass, this.dataValue.line_data)
                        }
                    }
                },
                line: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            colors: this.dataValue.colors,
            series: this.dataValue.line_data,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }

        });
    }

    dispatchFilter(linkValue, data, klass, lineData) {
        if (klass == '') {
            klass = lineData.find((line) => line.name === data.point.series.name).klass
        }

        $(`.${klass.replace('OEM::', '')}_datatable_search`).val('')
        $(`.${klass.replace('OEM::', '')}_datatable_search`)[0].classList.remove('active')

        const vulnerabilityFilters = {
            submitButton: $('#oem_vulnerability_table_datatable_filters button.btn.btn-primary')[0],
            clearButton: $('#oem_vulnerability_table_datatable_filters button.text-primary.mt-1')[0],
            monthSelect: $('#oem_vulnerability_table_datatable_filters #month_select_filter')[0],
            yearSelect: $('#oem_vulnerability_table_datatable_filters #year_select_filter')[0]
        };

        const patchDetailFilters = {
            submitButton: $('#oem_patch_detail_table_datatable_filters button.btn.btn-primary')[0],
            clearButton: $('#oem_patch_detail_table_datatable_filters button.text-primary.mt-1')[0],
            monthSelect: $('#oem_patch_detail_table_datatable_filters #month_select_filter')[0],
            yearSelect: $('#oem_patch_detail_table_datatable_filters #year_select_filter')[0]
        };

        if (linkValue == '') {
            let is_vulns = data.point.series.name.includes('Vulnerabilities')
            const filters = is_vulns ? vulnerabilityFilters : patchDetailFilters;
            $(`a[data-id=${is_vulns ? 'Vulnerabilities' : 'Patches'}]`)[0].click();
            window.scrollTo(0, 0);
            filters.clearButton.click();
            mdb.Select.getInstance(filters.monthSelect).setValue(data.point.category);
            mdb.Select.getInstance(filters.yearSelect).setValue('2023');
            filters.submitButton.click();
        } else {
            $(`a[data-id=${linkValue}]`)[0].click();
            window.scrollTo(0, 0);
            const filters = klass === 'OEM::Vulnerability' ? vulnerabilityFilters : patchDetailFilters;
            filters.clearButton.click();
            mdb.Select.getInstance(filters.monthSelect).setValue(data.point.category);
            mdb.Select.getInstance(filters.yearSelect).setValue('2023');
            if (klass === 'OEM::Vulnerability') {
                mdb.Select.getInstance($('#oem_vulnerability_table_datatable_filters #Severity_select_filter')[0]).setValue('5');
            }
            filters.submitButton.click();
        }
    }
}