import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton", "licensesCount", "notes", "partnerId", "licenseShortName", "alertContainer", "tenantId"];

  connect() {
    this.table = $('#tenant-licenses-usage').DataTable();
  }

  async deallocate(event) {
    event.preventDefault();

    const deallocateRow = $(event.currentTarget).closest('tr');
    const row = deallocateRow.prev();
    const rowIndex = this.table.row(row).index();

    const partnerId = this.partnerIdTarget.value;
    const licenseCount = this.licensesCountTarget.value;
    const tenantId = this.tenantIdTarget.value;
    const notes = this.notesTarget.value;
    const licenseShortName = this.licenseShortNameTarget.value;

    if (!partnerId || !licenseCount || isNaN(licenseCount) || !licenseShortName) {
      alert("Please fill out all fields correctly.");
      return;
    }

    const params = {
      notes: notes,
      process: "deallocate_from_tenant",
      partner_id: partnerId,
      tenant_id: tenantId,
      license_id: licenseShortName,
      license_count: parseInt(licenseCount, 10)
    };

    try {
      fetch("/tenant_licenses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify(params)
      })
        .then(response => response.json())
        .then(data => {
          const $alert = document.getElementById('alert-container');
          $alert.innerHTML = data.html;
          $($alert).fadeIn('normal', function () {
            $(this).delay(5200).fadeOut();
          });
          if (data.tenant_allocated && data.tenant_available)
            this.updateTenantRow(rowIndex, data.tenant_allocated, data.tenant_available)

          if (data.partner_allocated && data.partner_available)
            this.updatePartnerRow(licenseShortName, data.partner_allocated, data.partner_available)
        });
    } catch (error) {
      console.error("Deallocate licenses failed:", error);
      alert(error);
    }
    this.submitButtonTarget.disabled = true;
  }

  async allocate(event) {
    event.preventDefault()

    const allocateRow = $(event.currentTarget).closest('tr')
    const row = allocateRow.prev()
    const rowIndex = this.table.row(row).index()

    const tenantId = this.tenantIdTarget.value;
    const partnerId = this.partnerIdTarget.value
    const licenseCount = this.licensesCountTarget.value
    const notes = this.notesTarget.value
    const licenseShortName = this.licenseShortNameTarget.value

    if (!partnerId || !licenseCount || isNaN(licenseCount) || !licenseShortName) {
      alert("Please fill out all fields correctly.")
      return
    }

    const params = {
      notes: notes,
      process: 'allocate_to_tenant',
      tenant_id: tenantId,
      partner_id: partnerId,
      license_id: licenseShortName,
      license_count: parseInt(licenseCount, 10)
    }

    try {
      fetch("/tenant_licenses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify(params)
      })
        .then(response => response.json())
        .then(data => {
          const $alert = document.getElementById('alert-container');
          $alert.innerHTML = data.html;
          $($alert).fadeIn('normal', function () {
            $(this).delay(5200).fadeOut();
          });

          if (data.tenant_allocated && data.tenant_available)
            this.updateTenantRow(rowIndex, data.tenant_allocated, data.tenant_available)

          if (data.partner_allocated && data.partner_available)
            this.updatePartnerRow(licenseShortName, data.partner_allocated, data.partner_available)
        });
    } catch (error) {
      console.error("Allocate from tenant failed:", error);
      alert(error);
    }
    this.submitButtonTarget.disabled = true;
  }

  updateTenantRow(rowIndex, allocated, available) {
    const updatedRowData = this.table.row(rowIndex).data();
    updatedRowData[3] = allocated;
    updatedRowData[4] = available;
    this.table.row(rowIndex).data(updatedRowData).draw();
  }

  updatePartnerRow(licenseShortName, allocated, available) {
    this.partner_table = $('#manage-partner-licenses-pool').DataTable()
    const partnerRowIndex = this.partner_table.column(0).data().indexOf(licenseShortName)
    const updatedRowData = this.partner_table.row(partnerRowIndex).data()
    updatedRowData[1] = allocated
    updatedRowData[2] = available
    this.partner_table.row(partnerRowIndex).data(updatedRowData).draw()
  }

  collapseRow(event) {
    const activeRow = $(event.currentTarget).parents('tr').prev('tr')
    const row = this.table.row(activeRow)
    row.child.hide()
  }
}
