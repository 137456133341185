import { Controller } from "@hotwired/stimulus"

class FormInputs extends Controller {
  trimInput(event) {
    const trimmedValue = event.currentTarget.value.trim()
    event.currentTarget.value = trimmedValue
  }
}

export default FormInputs
