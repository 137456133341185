import { Controller } from '@hotwired/stimulus'

class DatatableSearchController extends Controller {
  static targets = ['table', 'input']

  search() {
    $(this.tableTarget).DataTable().search(this.inputTarget.value).draw()
  }
}

export default DatatableSearchController
