import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'tinyMceBox'
    ];

    static values = { height: Number };

    connect() {
        const targetElement = this.tinyMceBoxTarget;
        this.initTinyMceListenersForModalParent(targetElement)
    }

    initializeTinyMce(targetElement) {
        let targetId = targetElement.id
        let height = this.heightValue === 0 ? 300 : this.heightValue
        let options = {
            selector: `textarea#${targetId}`,
            promotion: false,
            branding: false,
            elementpath: false,
            height
        }

        if ($("body").hasClass('dark')) {
            options.skin = 'oxide-dark'
            options.content_css = 'dark'
        }

        tinymce.init(options)
    }

    initTinyMceListenersForModalParent(elm) {
        let modalParentId = elm.closest('.modal').id;

        if (modalParentId) {
            let modalParent = document.getElementById(modalParentId);
            let listenersAdded = modalParent.hasAttribute('data-event-listeners-added');
            if (!listenersAdded) {
                this.initializeTinyMce(elm);
                modalParent.addEventListener('show.mdb.modal', () => this.initializeTinyMce(elm));
                modalParent.addEventListener('hidden.mdb.modal', () => tinymce.remove());
                modalParent.setAttribute('data-event-listeners-added', 'true');
            }
        }
    }

}
