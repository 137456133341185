import { Controller } from "@hotwired/stimulus"

class IntegrationGeneratorController extends Controller {
  static targets = ['form', 'review']

  connect() {
    console.log(this.getFormElements())
  }


  getFormElements() {
    let valueObj = {};

    [...this.formTarget.elements].forEach(el => {
      el.addEventListener('change', () => {
        valueObj[el.id] = el.value
        this.#reviewTemplate(valueObj)
      })
    })

  }

  #reviewTemplate(obj) {
    let template = `
      <div class="row">
        <div class="col">
          <h2>Basic Information</h2>
          <div class="border-bottom py-3">
            <div><strong>Name</strong></div>
            <div>${this.#reviewFormatter(obj.name)}</div>
          </div>
          <div class="border-bottom py-3">
            <div><strong>Table Name</strong></div>
            <div>${this.#reviewFormatter(obj.table_name)}</div>
          </div>
          <div class="border-bottom py-3">
            <div><strong>Index</strong></div>
            <div>${this.#reviewFormatter(obj.index)}</div>
          </div>
          <div class="border-bottom py-3">
            <div><strong>Index Number</strong></div>
            <div>${this.#reviewFormatter(obj.index_number)}</div>
          </div>
          <div class="border-bottom py-3">
            <div><strong>Category</strong></div>
            <div>${this.#reviewFormatter(obj.category)}</div>
          </div>
        </div>
      </div>
    `
    this.reviewTarget.innerHTML = template
  }

  #reviewFormatter(value) {
    return value || ''
  }
}

export default IntegrationGeneratorController
