import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['render', 'search', 'selectInput']
    static values = {
        columns: Array,
        defaultSort: Array,
        tableId: String
    }

    connect() {
        this.render()
    }

    render() {
        const controller= this
        const searchTarget = controller.searchTarget
        const columns = controller.columnsValue
        $(this.renderTarget)
          .DataTable({
            order: [3,"asc"],
            pageLength: 10,
            lengthMenu: [10, 100, 500, 1000],
            ajax: {
                url: '/ransomware_exfiltration_detection_logs',
                data: (d) => {
                    d.search.value = $(searchTarget).val();
                    return $.extend({}, d,);
                }
            },
            serverSide: true,
            dom: '<"top">tr<"bottom"<"d-flex justify-content-between align-items-center border-top pt-3"<""i><"d-flex align-items-center"lp>>><"clear">', // This sets the order of the datatable elements, and puts the "entries per page" at the bottom
            columns: columns,
              columnDefs: [ {
                  targets: [4, 5, 6, 7, 8],
                  orderable: false
              } ]
        });
    }

    refreshTable() {
        this.drawTable()
    }

    enterSearch(e) {
        if (this.noSearchValue() || e.key !== 'Enter') { return }
        this.drawTable()
    }

    search() {
        if (this.noSearchValue()) { return }
        this.drawTable()
    }

    reset() {
        if (this.noSearchValue()) { this.drawTable() }
    }

    noSearchValue() {
        return this.searchTarget.value === ""
    }

    drawTable() {
        $(this.renderTarget).DataTable().draw()
    }
}
