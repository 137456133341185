import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['render']
    static values = {
        change: Number,
        greenOnNegative: Boolean,
    };

    connect() {
        this.updateDisplay();
    }

    updateDisplay() {
        const isPositive = this.changeValue > 0;
        const isZero = this.changeValue === 0;
        let cssClass = this.greenOnNegativeValue
            ? isPositive
                ? "text-danger"
                : "text-success"
            : isPositive
                ? "text-success"
                : "text-danger";
        if (isZero === true) {
            cssClass = "text-success"
        }
        const upDownIcon = isZero ? "" : isPositive ? "fa-regular fa-up-long" : "fa-regular fa-down-long";
        const plus = isPositive ? "+" : "";

        const htmlContent = `<span class="${cssClass}"><strong>${plus}${this.changeValue}&nbsp;</strong><i class="fa ${upDownIcon} ${cssClass} text-lg up_down_arrows"></i>&nbsp;Last Scan</span>`;
        $(this.renderTarget).html(htmlContent);
    }
}
