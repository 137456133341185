import { Controller } from "@hotwired/stimulus"

class FetchController extends Controller {

  async getData({ params }) {
    try {
      const response = await fetch(params.url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default FetchController
