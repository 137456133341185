import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton", "licensesCount", "notes", "partnerId", "licenseShortName", "alertContainer", "tenantId"];

  connect() {
    this.table = $('#manage-partner-licenses-pool').DataTable();
  }

  async sellback(event) {
    event.preventDefault();

    const sellbackRow = $(event.currentTarget).closest('tr');
    const row = sellbackRow.prev();
    const rowIndex = this.table.row(row).index();

    const partnerId = this.partnerIdTarget.value;
    const licenseCount = this.licensesCountTarget.value;
    const notes = this.notesTarget.value;
    const licenseShortName = this.licenseShortNameTarget.value;

    if (!partnerId || !licenseCount || isNaN(licenseCount) || !licenseShortName) {
      alert("Please fill out all fields correctly.");
      return;
    }

    const params = {
      notes: notes,
      process: "sell_back",
      partner_id: partnerId,
      license_id: licenseShortName,
      license_count: parseInt(licenseCount, 10)
    };

    try {
      fetch("/partner_licenses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify(params)
      })
        .then(response => response.json())
        .then(data => {
          const $alert = document.getElementById('alert-container');
          $alert.innerHTML = data.html;
          $($alert).fadeIn('normal', function () {
            $(this).delay(5200).fadeOut();
          });
          if (data.partner_allocated && data.partner_available)
            this.updateRow(rowIndex, data.partner_allocated, data.partner_available);
        });
    } catch (error) {
      const $alert = document.getElementById('alert-container');
      $alert.innerHTML = error.html;
      $($alert).fadeIn('normal', function () {
        $(this).delay(5200).fadeOut();
      });
    }
    this.submitButtonTarget.disabled = true;
  }

  async purchase(event) {
    event.preventDefault();

    const purchaseRow = $(event.currentTarget).closest('tr');
    const row = purchaseRow.prev();
    const rowIndex = this.table.row(row).index();

    const partnerId = this.partnerIdTarget.value;
    const licenseCount = this.licensesCountTarget.value;
    const notes = this.notesTarget.value;
    const licenseShortName = this.licenseShortNameTarget.value;

    if (!partnerId || !licenseCount || isNaN(licenseCount) || !licenseShortName) {
      alert("Please fill out all fields correctly.");
      return;
    }

    const params = {
      notes: notes,
      process: "purchase_licenses",
      partner_id: partnerId,
      license_id: licenseShortName,
      license_count: parseInt(licenseCount, 10)
    };

    try {
      fetch("/partner_licenses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify(params)
      })
        .then(response => response.json())
        .then(data => {
          const $alert = document.getElementById('alert-container');
          $alert.innerHTML = data.html;
          $($alert).fadeIn('normal', function () {
            $(this).delay(5200).fadeOut();
          });
          if (data.partner_allocated && data.partner_available)
            this.updateRow(rowIndex, data.partner_allocated, data.partner_available);
        });
    } catch (error) {
      console.error("Purchase failed:", error);
      alert(error);
    }
    this.submitButtonTarget.disabled = true;
  }

  async allocate_to_tenant(event) {
    event.preventDefault();

    const allocateRow = $(event.currentTarget).closest('tr');
    const row = allocateRow.prev();
    const rowIndex = this.table.row(row).index();

    const partnerId = this.partnerIdTarget.value;
    const tenantId = this.tenantIdTarget.value;
    const licenseCount = this.licensesCountTarget.value;
    const notes = this.notesTarget.value;
    const licenseShortName = this.licenseShortNameTarget.value;

    if (!tenantId || !partnerId || !licenseCount || isNaN(licenseCount) || !licenseShortName) {
      alert("Please fill out all fields correctly.");
      return;
    }

    const params = {
      notes: notes,
      process: "allocate_to_tenant",
      partner_id: partnerId,
      tenant_id: tenantId,
      license_id: licenseShortName,
      license_count: parseInt(licenseCount, 10)
    };

    try {
      fetch("/partner_licenses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
        },
        body: JSON.stringify(params)
      })
        .then(response => response.json())
        .then(data => {
          const $alert = document.getElementById('alert-container');
          $alert.innerHTML = data.html;
          $($alert).fadeIn('normal', function () {
            $(this).delay(5200).fadeOut();
          });
          if (data.partner_allocated && data.partner_available)
            this.updateRow(rowIndex, data.partner_allocated, data.partner_available);

          if (data.tenant_allocated && data.tenant_available) {
            this.tenant_table = $('#tenant-licenses-usage').DataTable()
            const tenantRowIndex = this.tenant_table.column(0).data().indexOf(allocateRow.find("#select-name").val())
            const updatedRowData = this.tenant_table.row(tenantRowIndex).data();
            updatedRowData[2] = data.tenant_allocated;
            updatedRowData[4] = data.tenant_available;
            this.tenant_table.row(tenantRowIndex).data(updatedRowData).draw();
          }
        });
    } catch (error) {
      console.error("Allocate to tenant failed:", error);
      alert(error);
    }
    this.submitButtonTarget.disabled = true;
  }

  updateRow(rowIndex, allocated, available) {
    const updatedRowData = this.table.row(rowIndex).data();
    updatedRowData[1] = allocated;
    updatedRowData[2] = available;
    this.table.row(rowIndex).data(updatedRowData).draw();
  }

  collapseRow(event) {
    const activeRow = $(event.currentTarget).parents('tr').prev('tr')
    const row = this.tenantLicenseTable.row(activeRow)
    row.child.hide()
  }
}
