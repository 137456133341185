import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['input'];
    static values = { options: Array };

    connect() {
        this.autocomplete();
    }

    autocomplete() {
        const dataFilter = (value) => {
            return this.optionsValue.filter((item) => {
                return item.toLowerCase().startsWith(value.toLowerCase());
            });
        };

        new mdb.Autocomplete(this.inputTarget, {
            filter: dataFilter
        });
    }
}