import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['render']
  // the data value accepts array of hashes containing label and value keys
  // if loading from an ajaxUrl, don't use the data value and vice versa
  static values = { ajaxUrl: String, data: Array, options: Object }

  connect() {
    if (this.hasAjaxUrlValue || this.hasDataValue) { this.render() }
  }

  render() {
    $(this.renderTarget).selectize(this.selectizeOptions())
  }

  selectizeOptions() {
    let options = {}

    if (this.hasOptionsValue) {
      for (const [key, value] of Object.entries(this.optionsValue)) { options[key] = value }
    }

    for (const [key, value] of Object.entries(this.defaultOptions())) {
      if (options[key] === undefined) { options[key] = value }
    }

    if (options.render === undefined) {
      options.render = {
        item: function(item, escape) {
          return `<div>${(item[options.labelField] ? escape(item[options.labelField]) : '')}</div>`
        },
        option: function(item, escape) {
          return `<div>${escape(item[options.labelField])}</div>`
        }
      }
    }

    if (this.hasDataValue) { options.options = this.dataValue }

    if (this.hasAjaxUrlValue) {
      const url = this.ajaxUrlValue
      options.load = function(query, callback) {
        $.ajax({
          data: { query: query},
          dataType: 'json',
          method: 'GET',
          type: 'GET',
          url: url,
          error: function () {
            callback()
          },
          success: function (res) {
            callback(res)
          }
        })
      }
    }

    return options
  }

  defaultOptions() {
    return {
      create: false,
      labelField: 'label',
      loadThrottle: 300,
      maxItems: null,
      maxOptions: 1000,
      persist: true,
      preload: false,
      searchField: ['label'],
      sortField: 'label',
      valueField: 'value'
    }
  }
}
