import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["csvDownload", "render", "search"]
  static values = {
    columns: Array,
    options: Object,
    source: String,
    filterParams: Object
  }

  connect() {
    const mergedOptions = { ...this.defaultOptions(), ...this.optionsValue }
    this.render(mergedOptions)
  }

  render(options) {
    const controller = this

    const expandedColumns = [
      {
        className: 'dt-control',
        orderable: false,
        data: null,
        defaultContent: '<button class="btn btn-sm"><i class="fas fa-plus"></i></button>'
      },
      ...this.columnsValue
    ]

    this.table = $(this.renderTarget).DataTable({
      ajax: {
        url: controller.sourceValue,
        type: 'POST',
        data: function (d) {
          const searchValue = controller.hasSearchTarget ? controller.searchTarget.value?.trim() : null

          if (searchValue) {
            d.search.value = searchValue
            d.search.regex = false
          }

          if (controller.filterParamsValue) {
            Object.keys(controller.filterParamsValue).forEach((key) => {
              for (const columnIndex in d.columns) {
                if (d.columns[columnIndex].data === key) {
                  d.columns[columnIndex].search.value = controller.filterParamsValue[key]
                  d.columns[columnIndex].search.regex = false
                  break;
                }
              }
            })
          }

          return d
        }
      },
      columns: expandedColumns,
      ...options,
      drawCallback: function (settings) {
        let params = this.api().ajax.params();
        params.format = 'csv';

        const linkElement = controller.hasCsvDownloadTarget ? controller.csvDownloadTarget : null;

        if (linkElement) {
          $(linkElement).off('click').on('click', function (event) {
            event.preventDefault();

            function formatDate(date) {
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
              const day = String(date.getDate()).padStart(2, '0');
              return `${year}-${month}-${day}`;
            }

            $.ajax({
              url: controller.sourceValue,
              type: 'POST',
              contentType: 'application/json',
              data: JSON.stringify(params),
              success: function (data) {
                const blob = new Blob([data], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const tempLink = document.createElement('a');
                tempLink.href = url;
                tempLink.download = `csv_assets_${formatDate(new Date)}.csv`;
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                URL.revokeObjectURL(url);
              },
              error: function () {
                console.error('Failed to fetch CSV data');
              }
            });
          });
        }
      }
    })

    $(this.renderTarget).on('click', 'td.dt-control', this.handleChildRowToggle.bind(this))
  }

  async handleChildRowToggle(event) {
    const tr = $(event.target).closest('tr')
    const row = this.table.row(tr)
    const button = $(event.target).closest('button')
    const icon = button.find('i')

    if (row.child.isShown()) {
      row.child.hide()
      icon.removeClass('fa-minus').addClass('fa-plus')
      return
    }

    try {
      const rowData = row.data()
      const response = await $.ajax({
        url: '/cvm/detections/child_row',
        method: 'GET',
        data: { solution: rowData.solution }
      })
      row.child(response).show()
      icon.removeClass('fa-plus').addClass('fa-minus')
    } catch (error) {
      console.error('Error loading child row:', error)
    }
  }

  redraw() {
    $(this.renderTarget).DataTable().draw()
  }

  serialize(obj, prefix) {
    const str = [];
    for (const p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        const key = prefix ? `${prefix}[${encodeURIComponent(p)}]` : encodeURIComponent(p);
        const value = obj[p];
        // If the value is an object or an array, recursively encode it
        str.push((value !== null && typeof value === 'object') ?
          this.serialize(value, key) :
          `${key}=${encodeURIComponent(value)}`);
      }
    }
    return str.join('&');
  }

  defaultOptions() {
    return {
      dom: '<"top">tr<"bottom"<"d-flex justify-content-between align-items-center border-top pt-3"<""i><"d-flex align-items-center"lp>>><"clear">',
      lengthMenu: [100, 500, 1000, 2000],
      order: [[0, "asc"]],
      ordering: true,
      pageLength: 100,
      processing: true,
      searching: true,
      serverSide: true
    }
  }

  updateFilterParams(params) {
    this.filterParamsValue = params;
    this.redraw()
  }
}
