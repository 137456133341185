import { Controller } from '@hotwired/stimulus'

class IntegrationBookmarksController extends Controller {

  showSpinner({ target }) {
    target.innerHTML = this.#spinner()
  }

  #spinner() {
    return (`
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    `)
  }

}

export default IntegrationBookmarksController
